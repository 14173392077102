import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class DashboardService extends BaseService {
  static async getDashboard() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        "doctor/dashboard",
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDashboardMessages() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        "/doctor/dashboard/messages",
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getLookup(tag) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/lookup/${tag}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
