import account from "./account";
import auth from "./auth";
import dashboard from "./dashboard";
import patients from "./patients";
import prescription from "./prescription";
import step from "./step";
import user from "./user";
import message from "./message";
import reminder from "./reminder";
import upsell from "./upsell";
import globals from "./globals";
import notes from "./notes";
import reply from "./reply";
import earnings from "./earnings";
import doctor from "./doctor";
import socket from "./socket";

export default {
  account,
  auth,
  dashboard,
  patients,
  prescription,
  step,
  user,
  message,
  reminder,
  upsell,
  globals,
  notes,
  reply,
  earnings,
  doctor,
  socket,
};
