<template>
  <w-form
    :name="this.$route.name"
    title="Welcome to Winona."
    sub-title="Please sign up to continue"
  />
</template>

<script>
import wForm from "@/components/form/Form";

export default {
  name: "signup",
  components: {
    wForm,
  },
};
</script>
