import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class AccountService extends BaseService {
  static async getReassignmentRequest({ showActive }) {
    try {
      const response = await this.request({ auth: true }).get(
        `doctorreassignment/request${showActive ? "?status=active" : ""}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async requestDoctorReassignment(payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `doctorreassignment/request`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async editDoctorReassignment(doctorrequestid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `doctorreassignment/request/${doctorrequestid}`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeDoctorReassignment(doctorrequestid) {
    try {
      const response = await this.request({ auth: true }).delete(
        `doctorreassignment/request/${doctorrequestid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelDoctorReassignment(doctorrequestid) {
    try {
      const response = await this.request({ auth: true }).put(
        `doctorreassignment/request/${doctorrequestid}/cancel`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
