var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.processing
    ? _c(
        "div",
        { staticClass: "position-relative" },
        [
          _c("portal-target", { attrs: { name: "notes" } }),
          _c("portal-target", { attrs: { name: "pricing" } }),
          _c("sidebar", {
            attrs: { profileUrl: _vm.userImg, name: _vm.currentUser },
          }),
          _c("top-bar", {
            class: _vm.hideSidebar ? "dark-bg" : "",
            attrs: { profileUrl: _vm.userImg, name: _vm.currentUser },
          }),
          _c(
            "div",
            {
              class: [
                _vm.hideSidebar
                  ? "is-hide"
                  : this.$route.name == "messages"
                  ? ""
                  : "tw-pl-4 tw-pr-4 md:tw-pl-7 md:tw-pr-7",
                "main-content tw-relative",
              ],
            },
            [
              _c(
                "vue-page-transition",
                [
                  _c("router-view", {
                    attrs: { "doctor-name": _vm.currentUser },
                  }),
                  _c("router-view", { attrs: { name: "modal" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "notfit",
                "hide-header": "",
                "hide-header-close": "",
                centered: "",
                "hide-footer": "",
                size: "md",
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-6" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-icon", {
                        attrs: { name: "icon-logo", size: "30" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-card" },
                [
                  _c("h2", { staticClass: "mb-3 heading" }, [
                    _vm._v(
                      " Are you sure this patient is not fit for treatment? "
                    ),
                  ]),
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center",
                      attrs: { "align-h": "center", "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleModal("notfit")
                                },
                              },
                            },
                            [_vm._v("confirm")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("notfit")
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "intake",
                "header-class": "intake-header position-relative",
                "footer-class": `position-absolute view-notes-modal d-block shadow-sm ${
                  _vm.isViewNotesOpen ? "show-modal" : ""
                }`,
                size: "xl",
                scrollable: "",
                centered: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function ({ close }) {
                      return [
                        _c(
                          "b-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "b-row",
                              { staticClass: "p-3 mt-3" },
                              [
                                _c("b-col", [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "mb-0 bold",
                                      staticStyle: {
                                        "letter-spacing": "0.5px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "d-inline-block ml-3 text-secondary text-uppercase text-underline",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isViewNotesOpen = true
                                            },
                                          },
                                        },
                                        [_c("small", [_vm._v("View Notes")])]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("b-col", [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "mb-0 text-left bold",
                                      staticStyle: {
                                        "letter-spacing": "0.5px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.patientInfo.patientno) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { size: "sm", variant: "link" },
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "x-circle-fill",
                                variant: "secondary",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _c("ModalBodyViewNotes", {
                          attrs: { "view-notes-open": _vm.isViewNotesOpen },
                          on: {
                            "update:viewNotesOpen": function ($event) {
                              _vm.isViewNotesOpen = $event
                            },
                            "update:view-notes-open": function ($event) {
                              _vm.isViewNotesOpen = $event
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1340171626
              ),
            },
            [
              _c(
                "b-row",
                { staticClass: "p-4" },
                [
                  _vm.patientInfo.followupquestions != undefined
                    ? _c(
                        "b-col",
                        { staticClass: "mb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "mb-0",
                              attrs: { "align-v": "center" },
                            },
                            _vm._l(
                              _vm.patientInfo.followupquestions,
                              function (followups, index) {
                                return _c(
                                  "b-col",
                                  { key: index, attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "bold mb-4",
                                        staticStyle: {
                                          "letter-spacing": "0.5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              followups.type === "1YEAR"
                                                ? "1YR"
                                                : "10WK"
                                            ) +
                                            " Follow-Up Questions | " +
                                            _vm._s(
                                              _vm.parseDate(
                                                followups.timestampanswered,
                                                "MM/dd/yyyy hh:mmaaa"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "health-question-wrapper",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "health-question" },
                                          _vm._l(
                                            followups.followupanswer,
                                            function (
                                              questionitems,
                                              indexquestions
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key: indexquestions,
                                                  staticClass: "hq-list",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tw-pb-6" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "intake-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              indexquestions + 1
                                                            ) +
                                                              ". " +
                                                              _vm._s(
                                                                questionitems.question
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "pl-0 intake-input",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                questionitems.answer.replace(
                                                                  /(\r\n|\n|\r)/gm,
                                                                  "\r\n"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                    _c("hr", {
                                      staticClass: "mb-5 col-12",
                                      staticStyle: {
                                        "border-top-width": "2px",
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.patientInfo.questions
                    ? _c(
                        "b-col",
                        { staticClass: "mb-4", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "mb-4",
                              attrs: { "align-v": "center" },
                            },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "bold",
                                      staticStyle: {
                                        "letter-spacing": "0.5px",
                                      },
                                    },
                                    [_vm._v(" Health Questions ")]
                                  ),
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "health-question-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "health-question" },
                                      _vm._l(
                                        _vm.patientInfo.questions,
                                        function (healthQuestion, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "hq-list",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "tw-pb-6" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "intake-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(index + 1) +
                                                          ". " +
                                                          _vm._s(
                                                            healthQuestion.question
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  healthQuestion.question !=
                                                  "Stated allergies"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "pl-0 intake-input",
                                                          style: `color: ${_vm.setColor(
                                                            healthQuestion.highlightcolor
                                                          )};border-bottom: 1px solid #D2DDEC; border-radius: 4px;`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                healthQuestion.answer
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "pl-0 intake-input",
                                                          style: `color:${_vm.setColor(
                                                            healthQuestion.highlightcolor
                                                          )}`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                healthQuestion.answer.replace(
                                                                  /(\r\n|\n|\r)/gm,
                                                                  "\r\n"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }