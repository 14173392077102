import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class UpsellService extends BaseService {
  static async denyUpsellProduct(upsellId) {
    try {
      const response = await this.request({ auth: true }).put(
        `upsell/${upsellId}/deny`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
