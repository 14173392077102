var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    {
      class: [
        _vm.hideSidebar ? "no-sidebar" : "",
        "navbar-vertical fixed-left",
      ],
      attrs: { toggleable: "xl", type: "dark" },
    },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-navbar-brand",
            {
              staticClass: "pl-3 d-xl-none",
              staticStyle: { border: "0" },
              attrs: { to: "/" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/logo_primary.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "navbar-user d-xl-none" },
            [
              _c(
                "router-link",
                { attrs: { to: "/notification" } },
                [
                  _c("v-icon", {
                    class: _vm.notify ? "active" : "",
                    staticStyle: { cursor: "pointer" },
                    attrs: { name: "bell", variant: "primary", size: "20" },
                  }),
                ],
                1
              ),
              _c("profile-nav", {
                attrs: { profileUrl: _vm.profileUrl, name: _vm.name },
              }),
            ],
            1
          ),
          _c(
            "b-collapse",
            {
              staticClass: "d-none d-xl-flex pt-7",
              staticStyle: { "margin-top": "5rem" },
              attrs: { id: "nav-collapse", "is-nav": "" },
            },
            [
              _c(
                "b-navbar-nav",
                _vm._l(_vm.navList, function (list, i) {
                  return _c(
                    "b-nav-item",
                    {
                      key: i + "3",
                      attrs: {
                        to: "/" + list.link,
                        exact: "",
                        "exact-active-class": "active",
                      },
                    },
                    [
                      _c("v-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          name: list.icon,
                          size: "20",
                          variant: "tertiary",
                        },
                      }),
                      _vm._v(" " + _vm._s(list.name) + " "),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "d-none d-xl-flex" }),
          _c(
            "b-navbar-nav",
            { staticClass: "mb-4 d-none d-xl-flex" },
            [
              _c(
                "b-nav-item",
                {
                  attrs: { exact: "", "exact-active-class": "active" },
                  on: { click: _vm.redirectToHelpCenter },
                },
                [
                  _c("b-icon", {
                    staticClass: "mx-3",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      icon: "life-preserver",
                      size: "20",
                      variant: "tertiary",
                    },
                  }),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("Help Center")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("darkmode", { staticClass: "d-none d-xl-flex" }),
          _c("hr", { staticClass: "d-none d-xl-flex" }),
          _c("span", { staticClass: "d-none d-xl-flex" }, [
            _vm._v("©Winona " + _vm._s(_vm.getYear)),
          ]),
        ],
        1
      ),
      _vm.showSearch
        ? _c(
            "div",
            { staticStyle: { width: "100%", "background-color": "white" } },
            [
              _c(
                "div",
                { staticClass: "input-group input-group-flush" },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Search",
                      type: "search",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "input-group-append" },
                    [
                      _c(
                        "b-input-group-text",
                        [
                          _c("b-icon", {
                            attrs: { icon: "arrow-right", variant: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vue-page-transition", { attrs: { name: "fade-in-down" } }, [
                _vm.search
                  ? _c(
                      "div",
                      { staticClass: "search-holder" },
                      [
                        _c(
                          "b-container",
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-h": "center" } },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", md: "10" } },
                                  [
                                    _c("ul", { staticClass: "list-unstyled" }, [
                                      _c("li", [
                                        _c("h4", { staticClass: "bold mb-2" }, [
                                          _vm._v("Patient"),
                                        ]),
                                      ]),
                                      _c("li", [_vm._v("Result here")]),
                                      _c("li", [_vm._v("Result here")]),
                                    ]),
                                    _c("ul", { staticClass: "list-unstyled" }, [
                                      _c("li", [
                                        _c("h4", { staticClass: "bold mb-2" }, [
                                          _vm._v("Messages"),
                                        ]),
                                      ]),
                                      _c("li", [_vm._v("Result here")]),
                                      _c("li", [_vm._v("Result here")]),
                                    ]),
                                    _c("ul", { staticClass: "list-unstyled" }, [
                                      _c("li", [
                                        _c("h4", { staticClass: "bold mb-2" }, [
                                          _vm._v("Prescription"),
                                        ]),
                                      ]),
                                      _c("li", [_vm._v("Result here")]),
                                      _c("li", [_vm._v("Result here")]),
                                    ]),
                                    _c("h4", { staticClass: "bold" }, [
                                      _vm._v(" Sorry, nothing found for for "),
                                      _c("span", [_vm._v("“Lorem Ipsum”")]),
                                    ]),
                                    _c("h2", { staticClass: "bold" }, [
                                      _vm._v(" Showing "),
                                      _c("span", { staticClass: "bold" }, [
                                        _vm._v("0"),
                                      ]),
                                      _vm._v(" results for "),
                                      _c("span", [_vm._v("“Lorem Ipsum”")]),
                                    ]),
                                    _c("hr"),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("vue-page-transition", { attrs: { name: "fade-in-up" } }, [
                _vm.search
                  ? _c("div", { staticClass: "overlay-bg" })
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }