import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class ChatService extends BaseService {
  static async listConversation(page) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/conversation/${page}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listSingleConversation(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/conversation/patient/${patientid}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listSingleAdminConversation(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/conversation/patient/${patientid}/admin`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchConversation(page, searchQuery) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/conversation/patient/${page}?search=${searchQuery}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversation(conversationId) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationId}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationInfo(conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/v1/conversation/${conversationId}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listConversationMember(conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/v1/conversation/${conversationId}/members`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listMessage(conversationId, nextpage) {
    try {
      if (process.env.VUE_APP_MESSAGING_VERSION === "v2") {
        const response = await this.request({ auth: true, v3: true }).get(
          `/v1/conversation/${conversationId}/messages${
            nextpage ? `?cursor=${nextpage}` : ""
          }`,
          {
            headers: {
              ...(store.state.globals.currentDoctor.id && {
                doctorid: store.state.globals.currentDoctor.id,
              }),
            },
          }
        );
        return new ResponseWrapper(response, response.data);
      } else {
        const response = await this.request({ auth: true }).get(
          `/conversation/${conversationId}/message?nextpage=${nextpage}`,
          {
            headers: {
              ...(store.state.globals.currentDoctor.id && {
                doctorid: store.state.globals.currentDoctor.id,
              }),
            },
          }
        );
        return new ResponseWrapper(response, response.data.body);
      }
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAdminConversationDetails(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/conversation/patient/${patientid}/admin`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessage(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${data.id}/message`,
        {
          message: data.message,
          needsreply: data.needsreply,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessageWithAttachment(conversationid, data, config = {}) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${conversationid}/messageattachment`,
        data,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async ignoreMessage(conversationid) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/doctor/conversation/${conversationid}/ignore`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async snoozeMessage(conversationid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/snooze`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async needPatientReply(conversationid) {
    try {
      const response = await this.request({ auth: true, v3: true }).put(
        `/v1/conversation/${conversationid}/mark-needs-reply`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeNeedPatientReply(conversationid) {
    try {
      const response = await this.request({ auth: true, v3: true }).delete(
        `/v1/conversation/${conversationid}/clear-needs-reply`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markAsRead(conversationid, messageid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/message/${messageid}/read`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markConversationAsRead(conversationid) {
    try {
      const response = await this.request({ auth: true, v3: true }).patch(
        `/v1/conversation/${conversationid}/mark-as-read`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getUnreadMessageCount(conversationid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/conversation/${conversationid}/unreadmessagecount`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async forwardRequest(conversationid, payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${conversationid}/forwardmessage`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationForwardRequests(conversationid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationid}/forwardmessage`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSingleForwardRequest(conversationid, forwardedmessageid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationid}/forwardmessage/${forwardedmessageid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
