import { ChatService } from "@/services/chat.service.js";

export default {
  namespaced: true,
  state: {
    converstationList: [],
    conversationForwardRequests: [],
    currentPage: 1,
    pageCount: 1,

    patientUnreadMessageCount: 0,
  },
  mutations: {
    SET_CONVERSATION(state, converstationList) {
      state.converstationList = converstationList;
    },
    SET_CONVERSATION_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage;
    },
    SET_CONVERSATION_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount;
    },
    SET_CONVERSATION_FORWARD_REQUESTS(state, conversationForwardRequests) {
      state.conversationForwardRequests = conversationForwardRequests;
    },
    SET_PATIENT_UNREAD_MESSAGE_COUNT(state, patientUnreadMessageCount) {
      state.patientUnreadMessageCount = patientUnreadMessageCount;
    },
    INCREMENT_PATIENT_UNREAD_MESSAGE_COUNT(state) {
      state.patientUnreadMessageCount++;
    },
  },
  getters: {
    FETCH_CONVERSATION(state) {
      return state.converstationList;
    },
  },
  actions: {
    async setPatientUnreadMessageCount({ commit }, currentCount) {
      commit("SET_PATIENT_UNREAD_MESSAGE_COUNT", currentCount);
    },
    async incrementPatientUnreadMessageCount({ commit }) {
      commit("INCREMENT_PATIENT_UNREAD_MESSAGE_COUNT");
    },
    async getConversationList({ commit, state }) {
      try {
        commit("SET_CONVERSATION", []);
        if (state.currentPage <= state.pageCount) {
          const { data } = await ChatService.listConversation(
            state.currentPage
          );
          commit("SET_CONVERSATION", data.items);
          commit("SET_CONVERSATION_CURRENT_PAGE", data.currentPage);
          commit("SET_CONVERSATION_PAGE_COUNT", data.pageCount);
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getSingleConversationList({ commit }, { patientid }) {
      try {
        const { data } = await ChatService.listSingleConversation(patientid);
        commit("SET_CONVERSATION", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getSingleAdminConversationList({ commit }, { patientid }) {
      try {
        const { data } = await ChatService.listSingleAdminConversation(
          patientid
        );
        commit("SET_CONVERSATION", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async searchConversationList({ commit, state, dispatch }, searchQuery) {
      try {
        commit("SET_CONVERSATION", []);
        if (searchQuery) {
          const { data } = await ChatService.searchConversation(
            state.currentPage,
            searchQuery
          );
          commit("SET_CONVERSATION", data.items);
          commit("SET_CONVERSATION_CURRENT_PAGE", data.currentPage);
          commit("SET_CONVERSATION_PAGE_COUNT", data.pageCount);
        } else {
          await dispatch("getConversationList");
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async ignoreMessage({ dispatch }, { conversationid }) {
      await ChatService.ignoreMessage(conversationid);
      dispatch("dashboard/getDashboardData", null, { root: true });
    },
    async snoozeMessage({ dispatch }, { conversationid }) {
      await ChatService.snoozeMessage(conversationid);
      dispatch("dashboard/getDashboardData", null, { root: true });
    },
    async needPatientReply({}, conversationid) {
      try {
        await ChatService.needPatientReply(conversationid);
      } catch (e) {
        console.error(e);
        return e;
      }
    },
    async removeNeedPatientReply({}, conversationid) {
      try {
        await ChatService.removeNeedPatientReply(conversationid);
      } catch (e) {
        console.error(e);
        return e;
      }
    },
    setConversationListPage({ commit }, pageNumber) {
      commit("SET_CONVERSATION_CURRENT_PAGE", pageNumber);
    },
    nextConversationListPage({ commit, state }) {
      commit("SET_CONVERSATION_CURRENT_PAGE", state.currentPage + 1);
    },
    previousConversationListPage({ commit, state }) {
      commit("SET_CONVERSATION_CURRENT_PAGE", state.currentPage - 1);
    },
    async markAsRead({}, { conversationid, messageid }) {
      try {
        return ChatService.markAsRead(conversationid, messageid).then(
          (data) => data
        );
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async markConversationAsRead({}, { conversationid }) {
      try {
        return ChatService.markConversationAsRead(conversationid).then(
          (data) => data
        );
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getUnreadMessageCount({}, { conversationid }) {
      try {
        return ChatService.getUnreadMessageCount(conversationid).then(
          (data) => data
        );
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async forwardRequest({ dispatch }, { conversationid, payload }) {
      try {
        const { data } = await ChatService.forwardRequest(
          conversationid,
          payload
        );
        dispatch("getConversationForwardRequests", { conversationid });
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getConversationForwardRequests({ commit }, { conversationid }) {
      try {
        const { data } = await ChatService.getConversationForwardRequests(
          conversationid
        );
        commit("SET_CONVERSATION_FORWARD_REQUESTS", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getSingleForwardRequest(
      { commit },
      { conversationid, forwardedmessageid }
    ) {
      try {
        const { data } = await ChatService.getSingleForwardRequest(
          conversationid,
          forwardedmessageid
        );
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
