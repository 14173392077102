import { DoctorService } from "@/services/doctor.service";
import { DocumentService } from "@/services/document.service";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async getDoctors({ commit, dispatch }) {
      try {
        const { data } = await DoctorService.getDoctors();
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
