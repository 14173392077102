<template>
  <b-container fluid>
    <b-navbar toggleable="sm" fixed="top">
      <b-navbar-brand class="c-logo relative mb-2">
        <img src="@/assets/images/logo.svg" alt="" />
      </b-navbar-brand>
    </b-navbar>
    <b-row
      align-h="center"
      style="
        padding-top: 150px;
        padding-bottom: 100px;
        padding-left: 25px;
        padding-right: 25px;
      "
    >
      <b-col cols="12">
        <vue-page-transition>
          <router-view />
        </vue-page-transition>
      </b-col>
    </b-row>
    <w-footer />
  </b-container>
</template>

<script>
import wFooter from "@/components/partials/Footer";
export default {
  name: "personal-details",
  components: { wFooter },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 100px;
  background-color: $primary;
}
</style>
