export const NAV_LIST = [
  {
    name: "Dashboard",
    icon: "home",
    link: "",
  },
  {
    name: "Patients",
    icon: "file",
    link: "patients",
  },
  {
    name: "Messages",
    icon: "message",
    link: "message",
  },
  // {
  //   name: "Prescriptions",
  //   icon: "prescription",
  //   link: "prescriptions",
  // },
  {
    name: "Reminders",
    icon: "reminder",
    link: "reminders",
  },
  {
    name: "Saved Replies",
    icon: "replies",
    link: "replies",
  },
  {
    name: "My Earnings",
    icon: "earnings",
    link: "earnings",
  },
];
