var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "sm", fixed: "top" } },
        [
          _c("b-navbar-brand", { staticClass: "c-logo relative mb-2" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/logo.svg"), alt: "" },
            }),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        {
          staticStyle: {
            "padding-top": "150px",
            "padding-bottom": "100px",
            "padding-left": "25px",
            "padding-right": "25px",
          },
          attrs: { "align-h": "center" },
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("vue-page-transition", [_c("router-view")], 1)],
            1
          ),
        ],
        1
      ),
      _c("w-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }