<template>
  <div id="app" v-if="hydrated">
    <!-- Login and Sign Up -->
    <template v-if="formControl">
      <b-navbar-brand to="/" class="c-logo">
        <img
          v-if="logoColorSwitch"
          src="@/assets/images/icon_logo_white.svg"
          alt=""
        />
        <img v-else src="@/assets/images/icon_logo.svg" alt="" />
      </b-navbar-brand>
      <b-container fluid class="landing-page">
        <b-row style="height: 100vh">
          <b-col cols="12" md="4">
            <vue-page-transition>
              <router-view />
            </vue-page-transition>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-else>
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </template>
  </div>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { io } from "socket.io-client";

export default {
  name: "Winona-Doctor",
  data() {
    return {
      hydrated: false,
    };
  },
  computed: {
    formControl: function () {
      return (
        this.$route.name == "login" ||
        this.$route.name == "signup" ||
        this.$route.name == "new-password" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "invitation-code"
      );
    },

    logoColorSwitch: function () {
      return (
        this.$route.name == "signup" || this.$route.name == "invitation-code"
      );
    },
  },
  async mounted() {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("isAuthenticated") === "true"
    ) {
      const socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
        auth: {
          token: localStorage.getItem("accessToken"),
        },
        transports: ["websocket", "polling"],
      });

      this.$store.dispatch("socket/connectToSocket", socket);

      socket.on("connect", () => {
        console.log("Connected to server");
      });

      socket.on("connect_error", async (err) => {
        console.log(err);
        if (err.message === "Invalid token") {
          await AuthService.logout();
          this.$store.dispatch("socket/closeSocketConnection");
        } else {
          socket.io.opts.transports = ["polling", "websocket"];
        }
      });

      socket.on("token_expired", async (err) => {
        console.log(err);
        await AuthService.logout();
        this.$store.dispatch("socket/closeSocketConnection");
      });

      socket.on("request_error", (err) => {
        console.log(err);
      });

      socket.on("validation_error", (err) => {
        console.log(err);
      });

      socket.on("message", (data) => {
        if (
          ["patient-profile", "intakehistory", "order"].includes(
            this.$router.currentRoute.name
          ) &&
          data.userid === this.$route.params.id
        ) {
          this.$store.dispatch("message/incrementPatientUnreadMessageCount");
        }
      });
    }

    this.hydrated = true;
  },
};
</script>

<style lang="scss">
.icon-logo {
  width: 30px;
  height: 30px;
  background-image: url("./assets/images/modal/icon-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.darkmode .icon-logo {
  background-image: url("./assets/images/modal/dark-icon-logo.svg");
}

$svg-icons: "home", "file", "message", "rx", "prescription", "bell", "briefcase",
  "rx-request", "grid", "list";
$svg-active-icons: "home", "file", "message", "rx", "prescription";

.win-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 1.5em;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dark-bg {
  .win-icon.is-bell {
    background-image: url("#{$svg}/bell-white.svg");
  }
}

@each $icon in $svg-icons {
  .win-icon.is-#{$icon} {
    background-image: url("#{$svg}/#{$icon}.svg");
  }
}

@each $icon in $svg-active-icons {
  .nav-link.active .win-icon.is-#{$icon} {
    background-image: url("#{$svg}/active/#{$icon}.svg");
  }
}

.overlay-top,
.overlay-left,
.overlay-right,
.overlay-bottom {
  z-index: 99999;
  background: $primary !important;
  transition-duration: 0.2s !important;
}
</style>

<style lang="scss" scoped>
#app {
  position: relative;
}

.landing-page {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("./assets/images/bg.png");
}

// .cover-image {
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center center;
//   width: 100%;
//   overflow: hidden;
//   transition: background-image 1s ease-in-out;

//   &.is-login {
//     background-image: url("./assets/images/login_bg.png");
//   }

//   &.is-fpassword {
//     background-image: url("./assets/images/forgot_password.png");
//   }

//   &.is-npassword {
//     background-image: url("./assets/images/new_password.png");
//   }

//   &.is-invite-code,
//   &.is-signup {
//     background-image: url("./assets/images/signup_bg.png");
//   }
// }
</style>
