import { UsersService } from "@/services/users.service";
import { DocumentService } from "@/services/document.service";

export default {
  namespaced: true,
  state: {
    isDarkMode: false,
    unhandledRemindersCount: 0,
    isAddNotesModalOpen: false,
    routeHistory: [],

    currentDoctor: {
      id: "",
      name: "",
    },
  },
  mutations: {
    TOGGLE_DARK_MODE(state, payload) {
      state.isDarkMode = payload;
    },
    SET_UNHANDLED_REMINDERS_COUNT(state, payload) {
      state.unhandledRemindersCount = payload;
    },
    SET_ADD_NOTES_MODAL_STATE(state, payload) {
      state.isAddNotesModalOpen = payload;
    },
    PUSH_ROUTE_HISTORY(state, payload) {
      state.routeHistory.push({
        meta: payload.meta,
        fullPath: payload.fullPath,
      });
    },
    POP_ROUTE_HISTORY(state) {
      state.routeHistory.pop();
    },
    CLEAR_ROUTE_HISTORY(state) {
      state.routeHistory = [];
    },

    SET_CURRENT_DOCTOR(state, payload) {
      state.currentDoctor = payload;
    },
  },
  actions: {
    toggleDarkMode({ commit }, payload) {
      commit("TOGGLE_DARK_MODE", payload);
    },
    setUnhandledRemindersCount({ commit }, payload) {
      commit("SET_UNHANDLED_REMINDERS_COUNT", payload);
    },
    addToRouteHistory({ commit, getters }, payload) {
      commit("PUSH_ROUTE_HISTORY", payload);
    },
    removeFromRouteHistory({ commit }) {
      commit("POP_ROUTE_HISTORY");
    },
    clearRouteHistory({ commit }) {
      commit("CLEAR_ROUTE_HISTORY");
    },

    setCurrentDoctor({ commit }, payload) {
      commit("SET_CURRENT_DOCTOR", payload);
    },
  },
  getters: {
    previousRoute(state) {
      if (state.routeHistory.length > 0) {
        return state.routeHistory[state.routeHistory.length - 1];
      } else {
        return null;
      }
    },
  },
};
