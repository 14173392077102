import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class DocumentService extends BaseService {
  static async getDocuments() {
    try {
      const response = await this.request({ auth: true }).get("/document");
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async downloadDocuments(documentId) {
    try {
      const response = await this.request({ auth: true }).put(
        `/document/${documentId}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteDocument(documentId) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/document/${documentId}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
