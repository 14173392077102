import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class NotesService extends BaseService {
  static async getCustomerNotes(patientid) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/${patientid}/medicalnotes`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addCustomerNote(patientid, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `patient/${patientid}/medicalnotes`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
