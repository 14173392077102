export default {
  /**
   *  IMPORTANT: Don't add MOUNTED, CREATED, WATCH here
   */
  computed: {
    emailState() {
      let reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return this.email == "" ? null : reg.test(this.email) ? true : false;
    },
    passwordState() {
      let reg =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*(),.?";:{}|<>+=_-])(?=.{8,})/;
      return this.password == ""
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
    cpasswordState() {
      return this.cpassword == ""
        ? null
        : this.cpassword == this.password
        ? true
        : false;
    },
    phoneState() {
      let reg = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
      return this.phone == "" ? null : reg.test(this.phone) ? true : false;
    },
    cardState() {
      return this.cardNumber.length >= 4 ? true : false;
    },
    checkCardType() {
      let number = this.cardNumber;
      let reg = new RegExp("^4");
      if (number.match(reg)) return "Not a valid Visa Credit Card number!";
      reg = new RegExp("^5[1-5]");
      if (number.match(reg)) return "Not a valid Mastercard number";
      reg = new RegExp("^(34|37)");
      if (number.match(reg))
        return "Not a valid Amercican Express Credit Card number!";
      reg = new RegExp("^30[0-5]");
      if (number.match(reg)) return "Not a valid Diner's Club Card number!";
      reg = new RegExp("^3[0-9]");
      if (number.match(reg)) return "Not a valid JCB Card number!";
      reg = new RegExp("^6011");
      if (number.match(reg)) return "Not a valid Discover Card number!";
      reg = new RegExp("^62[0-9]");
      if (number.match(reg)) return "Not a valid Union Pay Card number!";
      return null;
    },
  },
};
