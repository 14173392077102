import { DashboardService } from "@/services/dashboard.service";
import { NotificationService } from "@/services/notification.service";

import moment from "moment";

export default {
  namespaced: true,
  state: {
    dashboardData: {},
    dashboardMessages: [],
    patients: [],
    notifications: [],
    dosespotNotificationCount: 0,
  },
  mutations: {
    SET_DASHBOARD_DATA(state, dashboardData) {
      state.dashboardData = dashboardData;
    },
    SET_DASHBOARD_MESSAGES(state, dashboardData) {
      state.dashboardMessages = dashboardData;
    },
    SET_PATIENTS(state, patientData) {
      state.patients = patientData;
    },
    SET_NOTIFICATIONS(state, notificationData) {
      state.notifications = notificationData;
    },
    SET_DOSESPOT_NOTIFICATION_COUNT(state, count) {
      state.dosespotNotificationCount = count;
    },
  },
  getters: {
    getDashboardData(state) {
      return state.dashboardData;
    },
    getDashboardMessages(state) {
      return state.dashboardMessages;
    },
    getPatients(state) {
      return state.patients;
    },
    getNotifications(state) {
      return state.notifications;
    },
  },
  actions: {
    getDashboardData({ commit }) {
      return DashboardService.getDashboard()
        .then(({ data: dashboard }) => {
          commit("SET_DASHBOARD_DATA", dashboard);
          commit("SET_PATIENTS", patients);
        })
        .catch((error) => error);
    },
    getDashboardMessages({ commit }) {
      return DashboardService.getDashboardMessages()
        .then(({ data: dashboard }) => {
          commit("SET_DASHBOARD_MESSAGES", dashboard);
        })
        .catch((error) => error);
    },
    resetDashboardData({ commit }) {
      commit("SET_DASHBOARD_DATA", {});
      commit("SET_PATIENTS", []);
    },
    getNotifications({ commit }, nextpage) {
      return NotificationService.getNotifications(nextpage)
        .then(({ data: notifications }) => {
          commit("SET_NOTIFICATIONS", notifications.items);
          return notifications;
        })
        .catch((error) => error);
    },
    markAsRead({ dispatch }, id) {
      return NotificationService.markAsRead(id)
        .then(({ data: notifications }) => {
          return notifications;
        })
        .catch((error) => error);
    },
    markAllAsRead({ commit }) {
      return NotificationService.markAllAsRead()
        .then(({ data: notifications }) => {
          dispatch("getNotifications");
          return notifications;
        })
        .catch((error) => error);
    },
  },
};
