import { UsersService } from "@/services/users.service";
import { DocumentService } from "@/services/document.service";

export default {
  namespaced: true,
  state: {
    username: "",
    currentUser: {},
    inviteCode: "",
  },
  mutations: {
    SET_USERNAME(state, name) {
      state.username = name;
    },
    SET_CURRENT_USER(state, currentUserData) {
      state.currentUser = currentUserData;
    },
    SET_INVITE_CODE(state, inviteCode) {
      state.inviteCode = inviteCode;
    },
  },
  getters: {
    getInviteCode(state) {
      return state.inviteCode;
    },
    getUser(state) {
      return state.currentUser;
    },
  },
  actions: {
    getUser({ commit, dispatch }) {
      return UsersService.getCurrent()
        .then((user) => {
          localStorage.setItem("userid", user.data.id);
          commit("SET_CURRENT_USER", user.data);
          dispatch(
            "globals/setUnhandledRemindersCount",
            user.data.unhandledreminders,
            { root: true }
          );
          return user.data;
        })
        .catch((error) => error);
    },
    getDocuments({ commit }) {
      return DocumentService.getDocuments()
        .then((documents) => {
          return documents.data;
        })
        .catch((error) => error);
    },
  },
};
