<template>
  <b-navbar
    toggleable="xl"
    type="dark"
    :class="[hideSidebar ? 'no-sidebar' : '', 'navbar-vertical fixed-left']"
  >
    <b-container fluid>
      <b-navbar-brand to="/" class="pl-3 d-xl-none" style="border: 0">
        <img src="@/assets/images/logo_primary.svg" alt="" />
      </b-navbar-brand>

      <div class="navbar-user d-xl-none">
        <router-link to="/notification">
          <v-icon
            name="bell"
            variant="primary"
            size="20"
            :class="notify ? 'active' : ''"
            style="cursor: pointer"
          ></v-icon>
        </router-link>

        <profile-nav :profileUrl="profileUrl" :name="name" />
      </div>

      <b-collapse
        id="nav-collapse"
        is-nav
        class="d-none d-xl-flex pt-7"
        style="margin-top: 5rem"
      >
        <b-navbar-nav>
          <b-nav-item
            v-for="(list, i) in navList"
            :key="i + '3'"
            :to="'/' + list.link"
            exact
            exact-active-class="active"
          >
            <v-icon
              :name="list.icon"
              size="20"
              variant="tertiary"
              style="cursor: pointer"
            ></v-icon>
            <!-- <i :class="['win-icon', 'is-' + list.icon]"></i> -->
            {{ list.name }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <hr class="d-none d-xl-flex" />
      <b-navbar-nav class="mb-4 d-none d-xl-flex">
        <b-nav-item
          exact
          exact-active-class="active"
          @click="redirectToHelpCenter"
        >
          <b-icon
            class="mx-3"
            icon="life-preserver"
            size="20"
            variant="tertiary"
            style="cursor: pointer"
          ></b-icon>
          <span class="ml-2">Help Center</span>
        </b-nav-item>
      </b-navbar-nav>
      <darkmode class="d-none d-xl-flex" />
      <hr class="d-none d-xl-flex" />
      <span class="d-none d-xl-flex">©Winona {{ getYear }}</span>
    </b-container>
    <div v-if="showSearch" style="width: 100%; background-color: white">
      <div class="input-group input-group-flush">
        <b-form-input
          placeholder="Search"
          type="search"
          v-model="search"
          autocomplete="off"
        ></b-form-input>
        <div class="input-group-append">
          <b-input-group-text>
            <b-icon icon="arrow-right" variant="primary"></b-icon>
          </b-input-group-text>
        </div>
      </div>

      <vue-page-transition name="fade-in-down">
        <div class="search-holder" v-if="search">
          <b-container>
            <b-row align-h="center">
              <b-col cols="12" md="10">
                <!-- template when there's a search item -->
                <ul class="list-unstyled">
                  <li><h4 class="bold mb-2">Patient</h4></li>
                  <li>Result here</li>
                  <li>Result here</li>
                </ul>
                <ul class="list-unstyled">
                  <li><h4 class="bold mb-2">Messages</h4></li>
                  <li>Result here</li>
                  <li>Result here</li>
                </ul>
                <ul class="list-unstyled">
                  <li><h4 class="bold mb-2">Prescription</h4></li>
                  <li>Result here</li>
                  <li>Result here</li>
                </ul>

                <!-- template when search result not found -->
                <h4 class="bold">
                  Sorry, nothing found for for <span>“Lorem Ipsum”</span>
                </h4>

                <!-- template when search result not found -->
                <h2 class="bold">
                  Showing <span class="bold">0</span> results for
                  <span>“Lorem Ipsum”</span>
                </h2>
                <hr />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </vue-page-transition>
      <vue-page-transition name="fade-in-up">
        <div class="overlay-bg" v-if="search"></div>
      </vue-page-transition>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import profileNav from "@/components/partials/AccountNav";
// import Notification from "@/components/partials/Notification";
const year = new Date().getFullYear();

import { NAV_LIST } from "@/constants/side-nav";
export default {
  name: "sidenav",
  components: {
    profileNav,
    // Notification,
  },
  props: {
    name: String,
    profileUrl: String,
  },
  data() {
    return {
      visible: true,
      notify: false,
      unread: 0,
      showSearch: false,
      search: null,
      navList: NAV_LIST,
    };
  },
  mounted() {
    // this.$store.dispatch("dashboard/getNotifications").then((res) => {
    //   for (let i = 0; i < this.notifications.length; i++) {
    //     let notification = this.notifications[i];
    //     if (!notification.timestampread) {
    //       this.unread += 1;
    //     }
    //   }
    //   if (this.unread > 0) {
    //     this.notify = true;
    //   }
    // });
  },
  watch: {
    notifications: {
      handler(notifications) {
        this.unread = 0;
        for (let i = 0; i < notifications.length; i++) {
          let notification = notifications[i];
          if (!notification.timestampread) {
            this.unread += 1;
          }
        }
        if (this.unread > 0) {
          this.notify = true;
        } else {
          this.notify = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    getYear() {
      return year;
    },
    ...mapGetters({
      notifications: "dashboard/getNotifications",
    }),
    hideSidebar() {
      return this.$route.name == "notification";
    },
  },
  methods: {
    redirectToHelpCenter() {
      window.open("https://doctor-help.bywinona.com", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-holder {
  position: relative;
  cursor: pointer;

  &.active {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: $secondary;
      border-radius: 10px;
    }
  }
}

.navbar-vertical {
  transition: margin-left ease-in-out 0.5s, background-color ease-in-out 0.5s,
    border-color ease-in-out 0.5s;
  margin-left: 0;
  background-color: $white;
  z-index: 888 !important;
}

.darkmode .navbar-vertical {
  background-color: #000;
  border-color: #000;
}

.navbar.fixed-left {
  @include media-breakpoint-up(xl) {
    position: fixed;
    width: 100%;
    left: 0;
    background-color: $primary;
    color: $tertiary;
    border-color: rgba($primary, 0.05);

    .form-control {
      color: $primary;

      &::placeholder,
      &:focus {
        color: $primary;
        background-color: $white;
      }
    }

    .text-primary {
      color: $primary !important;
    }

    .dropdown-toggle {
      color: $primary !important;
    }

    @include autofill($primary, $white);
  }

  @include media-breakpoint-down(xl) {
    position: fixed;
    border-color: #ccc;
    background-color: #fff;
    width: 100%;

    .dropdown-toggle {
      color: $primary;
    }
  }
}

.darkmode .navbar.fixed-left {
  background-color: #181818 !important;

  @include media-breakpoint-down(xl) {
    border-color: #181818 !important;
  }
}

// @media (min-width: 768px) {
//   .navbar-vertical.navbar-expand-md .navbar-brand {
//     text-align: left;
//     padding: 19px 1.5em;
//     margin-top: -15px;
//     margin-left: -1.4em;
//     margin-right: -1.4em;
//     border-bottom: 1px solid $border-color;
//   }

//   .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
//     border-left-width: 11px;
//     border-left-color: $tertiary;
//   }

//   .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
//     top: 0;
//     bottom: 0;
//   }
// }

.navbar-vertical.navbar-expand-lg .navbar-brand {
  @include media-breakpoint-up(xl) {
    text-align: left;
    padding: 19px 1.5em;
    margin-top: -15px;
    margin-left: -1.4em;
    margin-right: -1.4em;
    border-bottom: 1px solid $border-color;
  }
}

.navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
  $navbar-nav-link-padding-x: 1rem;
  @include media-breakpoint-up(xl) {
    padding-left: $navbar-nav-link-padding-x !important;
    padding-right: $navbar-nav-link-padding-x !important;
  }
}
.navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
  @include media-breakpoint-up(xl) {
    border-left-width: 11px;
    border-left-color: $tertiary;
  }
}

.navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
  @include media-breakpoint-up(xl) {
    top: 0;
    bottom: 0;
  }
}

.no-sidebar {
  @include media-breakpoint-up(xl) {
    margin-left: -250px;
  }
}

.nav-link,
.nav-link.active {
  color: $tertiary !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-weight: 500 !important;
}

.icon-holder {
  margin-left: 13px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.search-holder {
  position: fixed;
  top: 115px;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: $white;
  color: $primary !important;
  padding: 1.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
}

.overlay-bg {
  position: fixed;
  top: 114px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1039;
  background-color: rgba($primary, 0.5);
}
</style>
