var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      ref: "dropdown",
      attrs: { variant: "link", block: "", right: "", "no-caret": "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _vm.currentDoctor.id
                ? _c(
                    "span",
                    {
                      staticClass: "dropdown-toggle d-letter-spacing",
                      attrs: {
                        href: "#",
                        id: "sidebarIcon",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _c("div", { staticClass: "d-none d-xl-block mr-4" }, [
                        _c("small", { staticClass: "mb-0" }, [
                          _vm._v("Viewing"),
                        ]),
                        _c(
                          "h3",
                          { staticClass: "mb-0 bold" },
                          [
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentDoctor.name) + " "
                              ),
                            ],
                          ],
                          2
                        ),
                      ]),
                      _c("b-icon", {
                        attrs: {
                          icon: "caret-down-fill",
                          "font-scale": "0.75",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      staticClass: "dropdown-toggle d-letter-spacing",
                      attrs: {
                        href: "#",
                        id: "sidebarIcon",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.profileUrl
                        ? _c("b-avatar", {
                            staticClass: "mr-3",
                            attrs: {
                              variant: "secondary",
                              text: _vm.name.charAt(0),
                              src: _vm.profileUrl,
                            },
                          })
                        : _c("b-skeleton", {
                            attrs: { animation: "fade", type: "avatar" },
                          }),
                      _c(
                        "div",
                        { staticClass: "account-body d-none d-xl-block mr-4" },
                        [
                          _c(
                            "h3",
                            { staticClass: "mb-0 bold" },
                            [
                              !_vm.name
                                ? [
                                    _c("b-skeleton", {
                                      attrs: {
                                        animation: "fade",
                                        width: "140px",
                                      },
                                    }),
                                  ]
                                : [_vm._v(" Dr. " + _vm._s(_vm.name) + " ")],
                            ],
                            2
                          ),
                          !_vm.name
                            ? _c("b-skeleton", {
                                attrs: { animation: "fade", width: "100px" },
                              })
                            : _c("small", { staticClass: "mb-0" }, [
                                _vm._v("Primary Care Physician"),
                              ]),
                        ],
                        1
                      ),
                      _c("b-icon", {
                        attrs: {
                          icon: "caret-down-fill",
                          "font-scale": "0.75",
                        },
                      }),
                    ],
                    1
                  ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.currentDoctor.id
        ? _c(
            "div",
            [
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onLogoutDoctor.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Logout doctor ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm._l(_vm.navList, function (list, i) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: i + "3",
                    staticClass: "d-xl-none",
                    attrs: {
                      to: "/" + list.link,
                      exact: "",
                      "exact-active-class": "active",
                    },
                  },
                  [_vm._v(" " + _vm._s(list.name) + " ")]
                )
              }),
              _c("b-dropdown-divider", { staticClass: "d-xl-none" }),
              _vm._l(_vm.accountList, function (a, i) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: i + "1",
                    attrs: {
                      to: "/" + a.link,
                      exact: "",
                      "exact-active-class": "active",
                    },
                  },
                  [_vm._v(" " + _vm._s(a.name) + " ")]
                )
              }),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.redirectToHelpCenter } },
                [_vm._v(" Help Center ")]
              ),
              _c("b-dropdown-text", [_c("darkmode")], 1),
              _c("b-dropdown-divider", { staticClass: "d-xl-none" }),
              _c("b-dropdown-item", { on: { click: _vm.logOut } }, [
                _vm._v("Logout"),
              ]),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }