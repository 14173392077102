<template>
  <b-row class="content tw-mt-0 xl:tw-mt-8">
    <b-col cols="12" md="3" class="tw-hidden md:tw-block">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row align-v="center">
              <b-col cols="auto">
                <b-avatar
                  size="70px"
                  :src="user.profileUrl"
                  v-if="user.profileUrl"
                ></b-avatar>
                <b-skeleton
                  animation="fade"
                  v-else
                  type="avatar"
                  size="70px"
                ></b-skeleton>
              </b-col>
              <b-col>
                <template v-if="user.firstname || user.lastname">
                  <h3 class="mb-0 bold">
                    Dr. {{ user.firstname + " " + user.lastname }}
                  </h3>
                  <small class="mb-0">Primary Care Physician</small>
                </template>
                <template v-else>
                  <b-skeleton animation="fade"></b-skeleton>
                  <b-skeleton animation="fade" width="65%"></b-skeleton>
                </template>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body class="account-nav">
            <b-list-group class="d-none d-sm-none d-md-block">
              <b-list-group-item
                v-for="(a, i) in accountList"
                :key="i + '1'"
                :to="'/' + a.link"
                exact
                tabindex="0"
                aria-disabled="true"
                exact-active-class="active"
              >
                {{ a.name }}
              </b-list-group-item>
            </b-list-group>
            <b-list-group horizontal class="d-md-none">
              <b-list-group-item
                v-for="(a, i) in accountList"
                :key="i + '1'"
                :to="'/' + a.link"
                exact
                tabindex="0"
                aria-disabled="true"
                exact-active-class="active"
                class="text-center"
              >
                {{ a.name }}
              </b-list-group-item>
            </b-list-group>

            <b-list-group
              class="d-none d-sm-none d-md-block"
              v-if="user.superdoctor"
            >
              <b-list-group-item
                to="/monitor"
                exact
                tabindex="0"
                aria-disabled="true"
                exact-active-class="active"
              >
                Monitor
              </b-list-group-item>
            </b-list-group>
            <b-list-group horizontal class="d-md-none" v-if="user.superdoctor">
              <b-list-group-item
                to="/monitor"
                exact
                tabindex="0"
                aria-disabled="true"
                exact-active-class="active"
                class="text-center"
              >
                Monitor
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="d-md-none mb-4"></b-col>
    <b-col cols="12" md="9">
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
// import { UsersService } from "@/services/users.service";

export default {
  name: "account",
  data() {
    return {
      accountList: [
        {
          name: "Account Details",
          link: "account",
        },
        {
          name: "Security Settings",
          link: "security",
        },
        {
          name: "Uploaded Documents",
          link: "document",
        },
        {
          name: "Out of Office",
          link: "out-of-office",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    getUser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {})
        .catch((error) => {
          if (error.message) {
            return this.alertMessage("warning", error.message);
          }
        });
    },
  },
  async mounted() {
    window.analytics.page();
    await this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.content {
  // border: 1px solid $border-color;

  > div {
    background-color: rgba($primary, 0.015);
    padding: 0;

    .card {
      background-color: transparent;
      border: 0;
      margin-bottom: 0;
    }

    &:first-child {
      background-color: $quaternary;
    }
  }
}

.account-nav {
  .list-group {
    .list-group-item {
      border-color: transparent;
    }

    .list-group-item-action:hover {
      color: $white;
      background-color: rgba($secondary, 50%);
      border-color: transparent;
    }

    .list-group-item.active {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}
</style>
