var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "content tw-mt-0 xl:tw-mt-8" },
    [
      _c(
        "b-col",
        {
          staticClass: "tw-hidden md:tw-block",
          attrs: { cols: "12", md: "3" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm.user.profileUrl
                                ? _c("b-avatar", {
                                    attrs: {
                                      size: "70px",
                                      src: _vm.user.profileUrl,
                                    },
                                  })
                                : _c("b-skeleton", {
                                    attrs: {
                                      animation: "fade",
                                      type: "avatar",
                                      size: "70px",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _vm.user.firstname || _vm.user.lastname
                                ? [
                                    _c("h3", { staticClass: "mb-0 bold" }, [
                                      _vm._v(
                                        " Dr. " +
                                          _vm._s(
                                            _vm.user.firstname +
                                              " " +
                                              _vm.user.lastname
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("small", { staticClass: "mb-0" }, [
                                      _vm._v("Primary Care Physician"),
                                    ]),
                                  ]
                                : [
                                    _c("b-skeleton", {
                                      attrs: { animation: "fade" },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: {
                                        animation: "fade",
                                        width: "65%",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "account-nav", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-list-group",
                        { staticClass: "d-none d-sm-none d-md-block" },
                        _vm._l(_vm.accountList, function (a, i) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: i + "1",
                              attrs: {
                                to: "/" + a.link,
                                exact: "",
                                tabindex: "0",
                                "aria-disabled": "true",
                                "exact-active-class": "active",
                              },
                            },
                            [_vm._v(" " + _vm._s(a.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "b-list-group",
                        { staticClass: "d-md-none", attrs: { horizontal: "" } },
                        _vm._l(_vm.accountList, function (a, i) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: i + "1",
                              staticClass: "text-center",
                              attrs: {
                                to: "/" + a.link,
                                exact: "",
                                tabindex: "0",
                                "aria-disabled": "true",
                                "exact-active-class": "active",
                              },
                            },
                            [_vm._v(" " + _vm._s(a.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _vm.user.superdoctor
                        ? _c(
                            "b-list-group",
                            { staticClass: "d-none d-sm-none d-md-block" },
                            [
                              _c(
                                "b-list-group-item",
                                {
                                  attrs: {
                                    to: "/monitor",
                                    exact: "",
                                    tabindex: "0",
                                    "aria-disabled": "true",
                                    "exact-active-class": "active",
                                  },
                                },
                                [_vm._v(" Monitor ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.user.superdoctor
                        ? _c(
                            "b-list-group",
                            {
                              staticClass: "d-md-none",
                              attrs: { horizontal: "" },
                            },
                            [
                              _c(
                                "b-list-group-item",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    to: "/monitor",
                                    exact: "",
                                    tabindex: "0",
                                    "aria-disabled": "true",
                                    "exact-active-class": "active",
                                  },
                                },
                                [_vm._v(" Monitor ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-col", { staticClass: "d-md-none mb-4", attrs: { cols: "12" } }),
      _c(
        "b-col",
        { attrs: { cols: "12", md: "9" } },
        [_c("vue-page-transition", [_c("router-view")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }