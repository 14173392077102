import axios from "axios";

import { Http } from "./http.init";
import { ResponseWrapper, ErrorWrapper } from "./util";
import $store from "../store";
import $router from "../router";

let BEARER = "";
let API_URL = process.env.VUE_APP_API_URL;

export class AuthService {
  static async login({ email, password }) {
    try {
      const response = await axios.post(`${API_URL}/signin`, {
        email,
        password,
      });
      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });
      localStorage.setItem("isAuthenticated", true);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async signup(data) {
    try {
      const response = await axios.post(`${API_URL}/signup`, data);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async logout() {
    try {
      const response = await new Http({ auth: true }).post("signout", {});
      _resetAuthData();
      $router.push({ name: "login" }).catch(() => {});
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async globeLogout() {
    try {
      const response = await new Http({ auth: true }).post("globalsignout", {});
      _resetAuthData();
      $router.push({ name: "login" }).catch(() => {});
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens() {
    try {
      const response = await new Http({ auth: true }).post("refreshtoken", {});

      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      if (error.response.data.message !== "This token is still valid") {
        _resetAuthData();
        $router.push({ name: "login" }).catch(() => {});
        throw new ErrorWrapper(error);
      }
    }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const accessTokenExpDate = $store.state.auth.accessTokenExpDate - 10;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem("refreshToken"));
  }

  static setRefreshToken(status) {
    if (!["", "true"].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
      );
    }

    localStorage.setItem("refreshToken", status);
  }

  static getBearer() {
    return localStorage.getItem("token");
  }

  static setBearer(accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("token", `Bearer ${accessToken}`);
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken) {
  let payload = "";
  let tokenData = {};

  try {
    payload = accessToken.split(".")[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData() {
  // reset userData in store
  $store.commit("user/SET_CURRENT_USER", {});
  $store.commit("auth/SET_ATOKEN_EXP_DATE", null);
  // reset tokens
  localStorage.clear();
}

function _setAuthData({ accessToken, exp } = {}) {
  AuthService.setRefreshToken("true");
  AuthService.setBearer(accessToken);
  $store.commit("auth/SET_ATOKEN_EXP_DATE", exp);
}
