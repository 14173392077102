import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";

import store from "../store";

import VueJwtDecode from "vue-jwt-decode";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import Profile from "@/views/Profile.vue";
// import Contact from "@/views/ContactUs.vue";
import NotFound from "@/views/PageNotFound.vue";
import NewPassword from "@/views/NewPassword.vue";
import ResetPassword from "@/views/ForgotPassword.vue";
import GettingStarted from "@/views/GettingStarted.vue";
import InvitationCode from "@/views/InvitationCode.vue";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem("token")) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("token")) {
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: "*",
    redirect: "/404",
    meta: { transition: "overlay-up-down" },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Page not found",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      transition: "fade-in-left",
      title: "Login",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    meta: {
      transition: "fade-in-left",
      title: "Sign Up",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/invitation-code",
    name: "invitation-code",
    component: InvitationCode,
    meta: {
      transition: "fade-in-left",
      title: "Invitation Code",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ResetPassword,
    meta: {
      transition: "fade-in-left",
      title: "Forgot Password",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/new-password",
    name: "new-password",
    component: NewPassword,
    meta: {
      transition: "fade-in-left",
      title: "New Password",
    },
    beforeEnter: ifNotAuthenticated,
  },
  // {
  //   path: "/contact-us",
  //   name: "contact-us",
  //   component: Contact,
  //   meta: {
  //     transition: "fade",
  //     title: "Contact Us",
  //   },
  // },
  {
    path: "/personal-details",
    component: GettingStarted,
    beforeEnter: ifAuthenticated,
    children: [
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      {
        path: "/",
        component: () => import("@/components/step/UserInfo.vue"),
        name: "personal-details",
        meta: {
          transition: "fade-in-right",
          title: "Personal Information",
        },
      },
      // General tab is the default tab so we need to ensure the tab was active once we visited it
      {
        path: "/summary",
        component: () => import("@/components/step/Summary.vue"),
        name: "summary",
        meta: {
          transition: "fade-in-right",
          title: "Summary",
        },
      },
      {
        path: "/upload-document",
        component: () => import("@/components/step/Document.vue"),
        name: "document",
        meta: {
          transition: "fade-in-right",
          title: "Documents",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/components/dashboard/Dashboard.vue"),
        meta: {
          transition: "fade",
          title: "Home",
        },
      },
      {
        path: "/message",
        name: "messages",
        component: () => import("@/components/dashboard/Message.vue"),
        meta: {
          transition: "fade",
          title: "Message",
        },
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/components/partials/Notification.vue"),
        meta: {
          transition: "fade",
          title: "Notification",
        },
      },
      {
        path: "/earnings",
        name: "earnings",
        component: () => import("@/components/dashboard/Earnings.vue"),
        meta: {
          transition: "fade",
          title: "My Earnings",
        },
      },
      {
        path: "/patients",
        name: "patients",
        component: () => import("@/components/dashboard/Patient.vue"),
        meta: {
          transition: "fade",
          title: "Patients",
        },
      },
      {
        path: "/requests",
        name: "requests",
        component: () => import("@/components/dashboard/Request.vue"),
        meta: {
          transition: "fade",
          title: "Requests",
        },
      },
      {
        path: "/prescriptions",
        name: "prescriptions",
        component: () => import("@/components/dashboard/Prescription.vue"),
        meta: {
          transition: "fade",
          title: "Prescriptions",
        },
      },
      {
        path: "/prescriptions/order/:id",
        name: "order",
        component: () => import("@/components/prescription/Order.vue"),
        meta: {
          transition: "fade",
          title: "Order",
          isRedesigned: true,
        },
      },
      {
        path: "/replies",
        name: "replies",
        component: () => import("@/components/dashboard/Replies.vue"),
        meta: {
          transition: "fade",
          title: "Saved Replies",
        },
      },
      {
        path: "/prescriptions/order/:id/update/:prescriptionid",
        name: "update-order",
        component: () => import("@/components/prescription/UpdateOrder.vue"),
        meta: {
          transition: "fade",
          title: "Update Order",
          isRedesigned: true,
        },
      },
      {
        path: "/patients/verified/:id",
        name: "verified",
        component: () => import("@/components/patients/Verified.vue"),
        meta: {
          transition: "fade",
          title: "Verified",
          isRedesigned: true,
        },
      },
      {
        path: "/patients/:id/message",
        name: "patientmessage",
        component: () => import("@/components/patients/views/Message.vue"),
        meta: {
          transition: "fade",
          title: "Message Patient",
          isRedesigned: true,
        },
      },
      {
        path: "/patients/:id/intake",
        name: "intakehistory",
        component: () =>
          import("@/components/patients/views/IntakeHistory.vue"),
        meta: {
          transition: "fade",
          title: "Intake History",
          isRedesigned: true,
        },
      },
      {
        path: "/patients/:id/treatments",
        name: "treatments",
        component: () => import("@/components/patients/views/Treatments.vue"),
        meta: {
          transition: "fade",
          title: "Treatments",
          isRedesigned: true,
        },
      },
      {
        path: "/verification/:id",
        name: "verification",
        component: () => import("@/components/patients/Verification.vue"),
        meta: {
          transition: "fade",
          title: "Verification",
          isRedesigned: true,
        },
      },
      {
        path: "/reminders",
        name: "reminders",
        component: () => import("@/components/dashboard/Reminder.vue"),
        meta: {
          transition: "fade",
          title: "Reminders",
        },
      },
      {
        path: "/patients/profile/:id",
        name: "patient-profile",
        component: () => import("@/components/patients/PatientProfile.vue"),
        meta: {
          transition: "fade",
          title: "Patient Profile",
        },
      },
      {
        path: "/account",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "/",
            name: "account",
            component: () => import("@/components/account/Account.vue"),
            meta: {
              transition: "fade",
              title: "Account",
            },
          },
          {
            path: "/security",
            name: "security",
            component: () => import("@/components/account/Security.vue"),
            meta: {
              transition: "fade",
              title: "Security",
            },
          },
          {
            path: "/document",
            name: "documents",
            component: () => import("@/components/account/Document.vue"),
            meta: {
              transition: "fade",
              title: "Documents",
            },
          },
          {
            path: "/monitor",
            name: "monitor",
            component: () => import("@/components/account/Monitor.vue"),
            meta: {
              transition: "fade",
              title: "Monitor",
            },
          },
          {
            path: "/out-of-office",
            name: "out-of-office",
            component: () => import("@/components/account/TimeOff.vue"),
            meta: {
              transition: "fade",
              title: "Out of Office",
            },
          },
        ],
      },
    ],
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
const DEAFULT_TITLE = "Winona";
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
  if (to.meta.user) document.title = DEAFULT_TITLE + " | " + to.meta.user(to);
  else document.title = DEAFULT_TITLE + " | " + to.meta.title;

  const token = VueJwtDecode.decode(localStorage.getItem("accessToken") || "");
  if (token && token["custom:role"] != "DOCTOR") {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    next({ name: "login" });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !JSON.parse(localStorage.getItem("isAuthenticated"))
  )
    next({ name: "login" });
  else {
    if (to.meta.isRedesigned) {
      if (to.fullPath === store.getters["globals/previousRoute"]?.fullPath) {
        store.dispatch("globals/removeFromRouteHistory");
      } else {
        store.dispatch("globals/addToRouteHistory", from);
      }
    } else {
      store.dispatch("globals/clearRouteHistory");
    }
    next();
  }
});

export default router;
