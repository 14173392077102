import { NotesService } from "@/services/notes.service.js";

export default {
  namespaced: true,
  state: {
    notesList: [],
  },
  mutations: {
    SET_NOTES_LIST(state, notesList) {
      state.notesList = notesList;
    },
  },
  actions: {
    async fetchPatientNotesList({ commit }, { patientid }) {
      try {
        const { data } = await NotesService.getCustomerNotes(patientid);
        commit("SET_NOTES_LIST", data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async addNote({ dispatch }, { patientid, notes }) {
      try {
        const { data } = await NotesService.addCustomerNote(patientid, {
          notes,
        });
        dispatch("fetchPatientNotesList", { patientid });
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    clearNotesList({ commit }) {
      commit("SET_NOTES_LIST", []);
    },
  },
};
