import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class EarningService extends BaseService {
  static async getPatientsAllTime(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/patient/all?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNewPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/patient/new?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFollowupPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/patient/followup?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getCurrentInvoice() {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/dashboard`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getCurrentPatientsInvoice() {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/patient/currentperiod`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInvoiceHistory() {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/summary`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPaidMessagesList(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/patient/message?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async downloadInvoice(billingsummaryid) {
    try {
      const response = await this.request({ auth: true }).get(
        `billing/invoice/${billingsummaryid}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
