export default {
  namespace: true,
  state: {
    defaultReplies: [
      {
        default: true,
        id: "default_greeting",
        messagetemplatecategoryid: "default",
        name: "(Default) Patient Greeting",
        content:
          "Hi {patient}, this is Doctor {doctor}, looking forward to taking care of you.",
      },
    ],
  },
};
