import { EarningService } from "@/services/earning.service.js";

export default {
  namespaced: true,
  state: {
    patientList: [],
    newPatientList: [],
    followupPatientList: [],
    invoiceHistory: [],
    currentInvoice: {},
    currentPatientsInvoice: [],
    paidMessagesList: [],
  },
  mutations: {
    SET_PATIENTS_LIST(state, patientList) {
      state.patientList = patientList;
    },
    SET_NEW_PATIENTS_LIST(state, newPatientList) {
      state.newPatientList = newPatientList;
    },
    SET_FOLLOWUP_PATIENTS_LIST(state, followupPatientList) {
      state.followupPatientList = followupPatientList;
    },
    SET_INVOICE_HISTORY(state, invoiceHistory) {
      state.invoiceHistory = invoiceHistory;
    },
    SET_CURRENT_INVOICE(state, currentInvoice) {
      state.currentInvoice = currentInvoice;
    },
    SET_CURRENT_PATIENTS_INVOICE(state, currentPatientsInvoice) {
      state.currentPatientsInvoice = currentPatientsInvoice;
    },
    SET_PAID_MESSAGES_LIST(state, paidMessagesList) {
      state.paidMessagesList = paidMessagesList;
    },
  },
  actions: {
    async fetchPatientsAllTime({ commit }, nextpage) {
      try {
        const { data } = await EarningService.getPatientsAllTime(nextpage);
        commit("SET_PATIENTS_LIST", data.items);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchNewPatientsList({ commit }, nextpage) {
      try {
        const { data } = await EarningService.getNewPatients(nextpage);
        commit("SET_NEW_PATIENTS_LIST", data.items);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchFollowupPatientsList({ commit }, nextpage) {
      try {
        const { data } = await EarningService.getFollowupPatients(nextpage);
        commit("SET_FOLLOWUP_PATIENTS_LIST", data.items);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchCurrentInvoice({ commit }) {
      try {
        const { data } = await EarningService.getCurrentInvoice();
        commit("SET_CURRENT_INVOICE", data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchCurrentPatientsInvoice({ commit }) {
      try {
        const { data } = await EarningService.getCurrentPatientsInvoice();
        commit("SET_CURRENT_PATIENTS_INVOICE", data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchInvoiceHistory({ commit }) {
      try {
        const { data } = await EarningService.getInvoiceHistory();
        commit("SET_INVOICE_HISTORY", data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async fetchPaidMessagesList({ commit }, nextpage) {
      try {
        const { data } = await EarningService.getPaidMessagesList(nextpage);
        commit("SET_PAID_MESSAGES_LIST", data.items);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async downloadInvoice({ commit }, billingsummaryid) {
      try {
        const { data } = await EarningService.downloadInvoice(billingsummaryid);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
