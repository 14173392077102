export default {
  namespaced: true,
  state: {
    socket: null,
  },
  mutations: {
    SET_SOCKET(state, socket) {
      state.socket = socket;
    },
    REMOVE_SOCKET(state) {
      state.socket = null;
    },
  },
  getters: {
    getSocket(state) {
      return state.socket;
    },
  },
  actions: {
    connectToSocket({ commit }, socket) {
      commit("SET_SOCKET", socket);
    },
    closeSocketConnection({ state, commit }) {
      if (state.socket) {
        state.socket.disconnect();
        commit("REMOVE_SOCKET");
      }
    },
  },
};
