import { PrescriptionService } from "@/services/prescription.service.js";

export default {
  namespaced: true,
  state: {
    prescriptionOrders: [],
    prescriptionOrder: {},
    dosepotPrescription: {},
    prescriptionOrdersProducts: [],
    doctorPrescription: [],
    productDosage: [],

    isPrescribeModalOpen: false,
    isResumePrescriptionModalOpen: false,
    isCancelPrescriptionModalOpen: false,
    isRetryPrescriptionModalOpen: false,
    isDeletePrescriptionModalOpen: false,
    selectedPrescription: {},
  },
  mutations: {
    SET_PRESCRIPTION_ORDERS(state, orders) {
      state.prescriptionOrders = orders;
    },
    SET_PRESCRIPTION_ORDER(state, order) {
      state.prescriptionOrder = order;
    },
    SET_PRESCRIPTION_ORDER_PRODUCTS(state, prescriptions) {
      state.prescriptionOrdersProducts = prescriptions;
    },
    SET_DOCTOR_PRESCRIPTION(state, prescriptions) {
      state.doctorPrescription = prescriptions;
    },
    SET_PRESCRIPTION_DOSAGE(state, dosage) {
      state.prescriptionOrders = dosage;
    },
    SET_DOSEPOT_PRESCRIPTION(state, prescription) {
      state.dosepotPrescription = prescription;
    },
    SET_PRESCRIPTION_MODAL(state, payload) {
      state.isPrescribeModalOpen = payload;
    },
    SET_RESUME_PRESCRIPTION_MODAL(state, payload) {
      state.isResumePrescriptionModalOpen = payload;
    },
    SET_CANCEL_PRESCRIPTION_MODAL(state, payload) {
      state.isCancelPrescriptionModalOpen = payload;
    },
    SET_RETRY_PRESCRIPTION_MODAL(state, payload) {
      state.isRetryPrescriptionModalOpen = payload;
    },
    SET_DELETE_PRESCRIPTION_MODAL(state, payload) {
      state.isDeletePrescriptionModalOpen = payload;
    },
    SET_SELECTED_PRESCRIPTION(state, payload) {
      state.selectedPrescription = payload;
    },
  },
  getters: {
    getPrescriptionOrderPerPatient(state) {
      return state.prescriptionOrders;
    },
    getDosepotPrescription(state) {
      return state.dosepotPrescription;
    },
    getDoctorPrescription(state) {
      return state.doctorPrescription;
    },
    getPrescriptionOrderProducts(state) {
      return state.prescriptionOrdersProducts;
    },
    getProductDosageAndUnit(state) {
      return state.productDosage;
    },
  },
  actions: {
    prescribePrescription({ commit }, data) {
      return PrescriptionService.prescribePrescriptionOrder(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        commit("SET_PRESCRIPTION_ORDER", data);
        return data;
      });
    },

    checkPrescriptionOrder({ commit }, data) {
      // console.log(data)
      return PrescriptionService.checkPrescriptionOrder(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },

    getDosepotPrescription({ commit }, data) {
      // console.log(data)
      return PrescriptionService.getDosepotPrescription(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        commit("SET_PRESCRIPTION_ORDER", data);
        return data;
      });
    },

    deletePrescription({ commit }, data) {
      // console.log(data)
      return PrescriptionService.deletePrescriptionOrder(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },

    sendPrescriptionOrderConfirmation({ commit }, data) {
      // console.log(data)
      return PrescriptionService.sendPrescriptionOrderConfirmation(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },

    cancelPrescriptionOrder({ commit }, data) {
      return PrescriptionService.cancelPrescriptionOrder(data.prescriptionid, {
        cancelfuturerefill: data.cancelfuturerefill,
        cancellationnotes: data.cancellationnotes,
        cancellationreason: data.cancellationreason,
      }).then(({ data }) => {
        return data;
      });
    },

    cancelRefundPrescriptionOrder({ commit }, data) {
      return PrescriptionService.cancelRefundPrescriptionOrder(
        data.prescriptionid,
        {
          cancellationnotes: data.cancellationnotes,
          cancellationreason: data.cancellationreason,
          ...(data.hasOwnProperty("refund") && { refund: data.refund }),
        }
      ).then(({ data }) => {
        return data;
      });
    },

    stopPrescription({ commit }, data) {
      // console.log(data)
      return PrescriptionService.stopPrescription(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },
    continuePrescription({ commit }, data) {
      // console.log(data)
      return PrescriptionService.continuePrescription(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },
    retryPrescription({ commit }, data) {
      return PrescriptionService.retryPrescription(data.prescriptionid).then(
        ({ data }) => {
          return data;
        }
      );
    },
    pausePrescription({ commit }, { prescriptionid, data }) {
      return PrescriptionService.pausePrescription(prescriptionid, data).then(
        ({ data }) => {
          return data;
        }
      );
    },
    resumePrescription({ commit }, data) {
      return PrescriptionService.resumePrescription(
        data.prescriptionid,
        data.payload
      ).then(({ data }) => {
        return data;
      });
    },

    searchPrescription({ commit }, search) {
      return PrescriptionService.searchPrescriptionOrder(search)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => error);
    },

    searchPrescriptionOrderPerPatient({ commit }, data) {
      return PrescriptionService.searchPrescriptionOrderPerPatient(data)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => error);
    },
    refreshPrescriptionOrder({ commit }, data) {
      return PrescriptionService.getPrescriptionOrder(
        data.patientid,
        data.prescriptionid
      ).then(({ data }) => {
        return data;
      });
    },

    getPrescriptionOrder({ commit }, data) {
      return PrescriptionService.getPrescriptionOrder(
        data.patientId,
        data.prescriptionOrderId
      ).then(({ data }) => {
        commit("SET_PRESCRIPTION_ORDER", data);
        return data;
      });
    },
    getDoctorPrescription({ commit }, nextpage) {
      return PrescriptionService.getDoctorPrescription(nextpage).then(
        ({ data }) => {
          commit("SET_DOCTOR_PRESCRIPTION", data);
          return data;
        }
      );
    },
    getPrescriptionOrderPerPatient({ commit }, { patientid, nextpage }) {
      return PrescriptionService.getPrescriptionOrderPerPatient(
        patientid,
        nextpage
      ).then(({ data }) => {
        commit("SET_PRESCRIPTION_ORDERS", data);
        return data;
      });
    },
    getPrescriptionOrderProducts({ commit }, patientid) {
      return PrescriptionService.getPrescriptionOrderProducts(patientid).then(
        ({ data }) => {
          commit("SET_PRESCRIPTION_ORDER_PRODUCTS", data);
          return data;
        }
      );
    },
    getProductDosageAndUnit({ commit }, { patientid }) {
      return PrescriptionService.getProductDosageAndUnit(patientid).then(
        ({ data }) => {
          commit("SET_PRESCRIPTION_DOSAGE", data.items);
          return data;
        }
      );
    },
    generateDospatURL({ commit }, patientid) {
      return PrescriptionService.generateDospatURL(patientid).then(
        ({ data }) => {
          return data;
        }
      );
    },
    ignorePrescription({ commit }, { patientid, prescriptionorderid }) {
      return PrescriptionService.ignorePrescription(
        patientid,
        prescriptionorderid
      ).then(({ data }) => {
        return data;
      });
    },
    ignoreFollowup({ commit }, { patientid, patientfollowupid }) {
      return PrescriptionService.ignoreFollowup(
        patientid,
        patientfollowupid
      ).then(({ data }) => {
        return data;
      });
    },
    markFollowupComplete(
      { commit },
      { patientid, ids, followupactionnotes, followupactionid }
    ) {
      return PrescriptionService.markFollowupComplete({
        patientid,
        ids,
        followupactionnotes,
        followupactionid,
      }).then(({ data }) => {
        return data;
      });
    },
    togglePrescriptionModal({ commit }, payload) {
      commit("SET_PRESCRIPTION_MODAL", payload);
    },
    toggleCancelPrescriptionModal({ commit }, payload) {
      commit("SET_CANCEL_PRESCRIPTION_MODAL", payload);
    },
    toggleRetryPrescriptionModal({ commit }, payload) {
      commit("SET_RETRY_PRESCRIPTION_MODAL", payload);
    },
    toggleDeletePrescriptionModal({ commit }, payload) {
      commit("SET_DELETE_PRESCRIPTION_MODAL", payload);
    },
    setSelectedPrescription({ commit }, payload) {
      commit("SET_SELECTED_PRESCRIPTION", payload);
    },

    async stopDelay({ commit }, { patientid, prescriptionid }) {
      try {
        const { data } = await PrescriptionService.stopDelay(
          patientid,
          prescriptionid
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async bypassDelay({ commit }, { prescriptionid }) {
      try {
        const { data } = await PrescriptionService.bypassDelay(prescriptionid);
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async cancelPendingOrder({ commit }, { prescriptionid }) {
      try {
        const { data } = await PrescriptionService.cancelPendingOrder(
          prescriptionid
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async rushDelayTimer({ commit }, { prescriptionid }) {
      try {
        const { data } = await PrescriptionService.rushDelayTimer(
          prescriptionid
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async payNextInvoice({ commit }, { prescriptionid }) {
      try {
        const { data } = await PrescriptionService.payNextInvoice(
          prescriptionid
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async addRefill({ commit }, { prescriptionid, patientid, payload }) {
      try {
        const { data } = await PrescriptionService.addRefill(
          patientid,
          prescriptionid,
          payload
        );
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async updateShipmentDate(
      { commit },
      { prescriptionid, patientid, payload }
    ) {
      try {
        const { data } = await PrescriptionService.updateShipmentDate(
          patientid,
          prescriptionid,
          payload
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async updateTreatmentDate(
      { commit },
      { prescriptionid, patientid, payload }
    ) {
      try {
        const { data } = await PrescriptionService.updateTreatmentDate(
          patientid,
          prescriptionid,
          payload
        );
        return data;
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async restartTreatment({ commit }, { prescriptionid, patientid, payload }) {
      try {
        const { data } = await PrescriptionService.restartTreatment(
          patientid,
          prescriptionid,
          payload
        );
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async schedulePrescription(
      { commit },
      { prescriptionid, patientid, payload }
    ) {
      try {
        const { data } = await PrescriptionService.schedulePrescription(
          patientid,
          prescriptionid,
          payload
        );
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
