<template>
  <div :class="['top-bar d-none d-xl-block', hideSidebar ? 'no-sidebar' : '']">
    <b-row align-v="center" class="pl-3">
      <b-col cols="auto" class="pl-0 logo-cont">
        <router-link to="/" class="logo dark-bg">
          <img src="@/assets/images/logo.svg" alt="" />
        </router-link>
      </b-col>
      <b-col>
        <div class="input-group input-group-flush"></div>
      </b-col>

      <b-col cols="auto" class="pr-1">
        <router-link to="/reminders" class="notification">
          <div class="notification" style="position: relative; cursor: pointer">
            <v-icon name="reminder" size="20" style="cursor: pointer"></v-icon>
            <span
              v-if="unhandledreminders > 0"
              :style="{
                right:
                  unhandledreminders.toString().length > 1 ? '-6px' : '-3px',
              }"
              class="
                reminder-pill
                d-flex
                justify-content-center
                align-items-center
                badge badge-pill badge-secondary
                text-white
              "
            >
              {{ unhandledreminders }}
            </span>
          </div>
        </router-link>
      </b-col>
      <b-col cols="auto">
        <router-link to="/notification" class="notification">
          <v-icon
            name="bell"
            size="20"
            :class="notify ? 'active' : ''"
            style="cursor: pointer"
          ></v-icon>
        </router-link>
      </b-col>
      <b-col cols="auto">
        <AccountNav :profileUrl="profileUrl" :name="nameDisplay" />
      </b-col>
    </b-row>
    <vue-page-transition name="fade-in-down">
      <div class="search-holder" v-if="search">
        <b-container>
          <b-row align-h="center">
            <b-col cols="12" md="10">
              <!-- template when there's a search item -->
              <ul class="list-unstyled">
                <li><h4 class="bold mb-2">Patient</h4></li>
                <li>Result here</li>
                <li>Result here</li>
              </ul>
              <ul class="list-unstyled">
                <li><h4 class="bold mb-2">Messages</h4></li>
                <li>Result here</li>
                <li>Result here</li>
              </ul>
              <ul class="list-unstyled">
                <li><h4 class="bold mb-2">Prescription</h4></li>
                <li>Result here</li>
                <li>Result here</li>
              </ul>

              <!-- template when search result not found -->
              <h4 class="bold">
                Sorry, nothing found for for <span>“Lorem Ipsum”</span>
              </h4>

              <!-- template when search result not found -->
              <h2 class="bold">
                Showing <span class="bold">0</span> results for
                <span>“Lorem Ipsum”</span>
              </h2>
              <hr />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </vue-page-transition>
    <vue-page-transition name="fade-in-up">
      <div class="overlay-bg" v-if="search"></div>
    </vue-page-transition>

    <b-modal id="viewDosespotNotification" centered hide-footer size="xl">
      <b-row no-gutters>
        <b-col cols="12">
          <template v-if="isLoading && !this.dosespotNotificationUrl">
            <b-row align-v="center" style="height: 350px">
              <b-col cols="12" class="text-center">
                <h1 class="text-primary">Loading Dosespot...</h1>
              </b-col>
            </b-row>
          </template>
          <iframe
            v-else
            :src="this.dosespotNotificationUrl"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="yes"
            allowtransparency="true"
            :style="{ minHeight: iframeHeight + 'px' }"
          ></iframe>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AccountNav from "@/components/partials/AccountNav";
// import Notification from "@/components/partials/Notification";
export default {
  name: "top-bar",
  props: {
    name: String,
    profileUrl: String,
  },
  components: {
    AccountNav,
    // Notification,
  },
  data() {
    return {
      notify: false,
      unread: 0,
      search: null,
      dosespotNotificationUrl: "",
      isLoading: false,
      iframeHeight: "",
    };
  },
  computed: {
    ...mapGetters({
      notifications: "dashboard/getNotifications",
    }),
    dosespotNotificationCount() {
      return this.$store.state.dashboard.dosespotNotificationCount;
    },
    unhandledreminders() {
      return this.$store.state.globals.unhandledRemindersCount;
    },
    nameDisplay() {
      if (!this.name || this.name.toUpperCase() == "UNDEFINED UNDEFINED") {
        return "";
      } else {
        return this.name;
      }
    },
    hideSidebar() {
      return this.$route.name == "notification";
    },

    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  watch: {
    notifications: {
      handler(notifications) {
        this.unread = 0;
        for (let i = 0; i < notifications.length; i++) {
          let notification = notifications[i];
          if (!notification.timestampread) {
            this.unread += 1;
          }
        }
        if (this.unread > 0) {
          this.notify = true;
        } else {
          this.notify = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
  },
  async mounted() {
    await this.$store.dispatch("dashboard/getNotifications");
    for (let i = 0; i < this.notifications.length; i++) {
      let notification = this.notifications[i];
      if (!notification.timestampread) {
        this.unread += 1;
      }
    }
    if (this.unread > 0) {
      this.notify = true;
    }

    this.matchHeight();

    window.addEventListener("resize", () => {
      this.matchHeight();
    });
  },
};
</script>

<style scoped>
/deep/.modal-dialog {
  min-width: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

/deep/.modal-body {
  padding: 0;
}

/deep/.modal-header {
  border: none;
}
</style>

<style lang="scss" scoped>
.top-bar {
  position: fixed;
  z-index: 889;
  width: 100%;
  // padding-left: 250px;
  // background-color: transparent;
  border: 0;
  // border-bottom: 1px solid $border-color;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;

  &.no-sidebar {
    &:before {
      background-color: $primary;
    }
  }

  &:before {
    content: "";
    position: fixed;
    left: 250px;
    right: 0;
    height: 64px;
    background-color: $white;
    transition: all ease-in-out 0.5s;
  }

  .logo {
    display: block;
    width: 250px;
    height: 62.4px;
    line-height: 55px;
    padding-left: 30px;
    border-right: 1px solid $primary;
    transition: all ease-in-out 0.5s;
  }

  .form-control {
    border-bottom: 0;
  }
}

.darkmode .top-bar {
  &.no-sidebar {
    &:before {
      background-color: #181818;
    }
  }

  &:before {
    background-color: #181818;
  }

  .logo {
    border-right: transparent;
  }
}

.icon-holder {
  position: relative;
  cursor: pointer;

  &.active {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: $secondary;
      border-radius: 10px;
    }
  }
}

.notification-count {
  background-color: $secondary;
  color: white;
  padding: 2px;
  border-radius: 9999px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  top: 0;
  right: -8px;
}

// @media (min-width: 769px) {
//   .icon-holder {

//     svg path {
//       fill: $tertiary !important
//     }
//   }
// }

.search-holder {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: $white;
  color: $primary !important;
  padding: 1.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
}

.overlay-bg {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1039;
  background-color: rgba($primary, 0.5);
}

// .top-bar .logo {
//   @include media-breakpoint-up(xxl) {
//     height: (62.4px * 2);
//   }
// }

.top-bar .logo,
.logo-cont {
  @include media-breakpoint-up(md) {
    width: 250px;
  }

  // @include media-breakpoint-up(xxl) {
  //   width: 400px;
  // }
}

.reminder-pill {
  position: absolute;
  top: 0;
  font-size: 10px;
  line-height: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

// .top-bar .logo img {
//   @include media-breakpoint-up(xxl) {
//     width: 200px;
//     margin: 0 auto;
//     top: 25%;
//     position: relative;
//   }
// }

// .top-bar {
//   @include media-breakpoint-up(xxl) {
//     background-color: $white;
//   }
// }

// .notification .bell {
//   @include media-breakpoint-up(xxl) {
//     top: -20px;
//     transform: scale(1.5);
//   }
// }

// .notification .bell:after {
//   @include media-breakpoint-up(xxl) {
//     top: 11px;
//   }
// }

// .navbar-dark,
// .bg-dark,
// .dark-bg {

//   svg path {
//     fill: $tertiary !important
//   }
// }

// .navbar-light,
// .bg-light,
// .light-bg {

//   svg path {
//     fill: $primary !important
//   }
// }

// @media (min-width: 768px) {
//   .top-bar {
//     margin-left: 250px;
//   }
// }
</style>
