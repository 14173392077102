import { ReminderService } from "@/services/reminder.service.js";

export default {
  namespaced: true,
  state: {
    reminderList: [],
    pastDueList: [],
    todayList: [],
    thisWeekList: [],
    sortingOrder: "asc",
  },
  mutations: {
    SET_REMINDER_LIST(state, reminderList) {
      state.reminderList = reminderList;
    },
    SET_PASTDUE_REMINDER_LIST(state, reminderList) {
      state.pastDueList = reminderList;
    },
    SET_TODAY_REMINDER_LIST(state, reminderList) {
      state.todayList = reminderList;
    },
    SET_THISWEEK_REMINDER_LIST(state, reminderList) {
      state.thisWeekList = reminderList;
    },
    SET_SORTING_ORDER(state, sortingOrder) {
      state.sortingOrder = sortingOrder;
    },
  },
  actions: {
    async getReminderList({ commit, state }) {
      try {
        const { data } = await ReminderService.getReminders(state.sortingOrder);
        commit("SET_REMINDER_LIST", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getPastDueReminderList({ commit }) {
      try {
        const { data } = await ReminderService.getPastDueReminders();
        commit("SET_PASTDUE_REMINDER_LIST", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getTodayReminderList({ commit }) {
      try {
        const { data } = await ReminderService.getTodayReminders();
        commit("SET_TODAY_REMINDER_LIST", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getThisWeekReminderList({ commit }) {
      try {
        const { data } = await ReminderService.getThisWeekReminders();
        commit("SET_THISWEEK_REMINDER_LIST", data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async snoozeReminder({ dispatch }, reminderid) {
      try {
        const { data } = await ReminderService.snoozeReminder(reminderid);
        dispatch("getPastDueReminderList");
        dispatch("getTodayReminderList");
        dispatch("getThisWeekReminderList");
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async completeReminder(
      { commit, dispatch },
      { reminderid, routename, patientid }
    ) {
      try {
        const { data } = await ReminderService.completeReminder(reminderid);
        if (["patient-profile"].includes(routename)) {
          await dispatch("getPatientReminderList", { patientid });
        } else {
          dispatch("getReminderList");
          dispatch("getPastDueReminderList");
          dispatch("getTodayReminderList");
          dispatch("getThisWeekReminderList");
        }
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getPatientReminderList({ commit, state }, { patientid }) {
      try {
        const { data } = await ReminderService.getPatientReminder(
          patientid,
          state.sortingOrder
        );
        commit("SET_REMINDER_LIST", data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async addReminder({ dispatch }, { patientid, routename, payload }) {
      try {
        const { data } = await ReminderService.addReminder(payload);
        if (["patient-profile"].includes(routename)) {
          dispatch("getPatientReminderList", { patientid });
        } else {
          dispatch("getReminderList");
          dispatch("getPastDueReminderList");
          dispatch("getTodayReminderList");
          dispatch("getThisWeekReminderList");
        }

        // Set unhandled reminders count
        dispatch(
          "globals/setUnhandledRemindersCount",
          data.unhandledreminders,
          {
            root: true,
          }
        );
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async rescheduleReminder({ dispatch }, { reminderid, payload }) {
      try {
        const { data } = await ReminderService.updateReminder(
          reminderid,
          payload
        );
        dispatch("getPastDueReminderList");
        dispatch("getTodayReminderList");
        dispatch("getThisWeekReminderList");

        // Set unhandled reminders count
        dispatch(
          "globals/setUnhandledRemindersCount",
          data.unhandledreminders,
          {
            root: true,
          }
        );
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async reopenReminder(
      { dispatch },
      { patientid, routename, reminderid, payload }
    ) {
      try {
        const { data } = await ReminderService.reopenReminder(
          reminderid,
          payload
        );
        if (["patient-profile"].includes(routename)) {
          await dispatch("getPatientReminderList", { patientid });
        } else {
          dispatch("getReminderList");
          dispatch("getPastDueReminderList");
          dispatch("getTodayReminderList");
          dispatch("getThisWeekReminderList");
        }

        // Set unhandled reminders count
        dispatch(
          "globals/setUnhandledRemindersCount",
          data.unhandledreminders,
          {
            root: true,
          }
        );
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    toggleSortingOrder({ commit }, payload) {
      commit("SET_SORTING_ORDER", payload);
    },
  },
};
