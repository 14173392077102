var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      class: [
        _vm.name == "signup"
          ? "bg-secondary"
          : _vm.name == "invitation-code"
          ? "bg-secondary"
          : "bg-dark",
      ],
      staticStyle: { height: "100vh" },
    },
    [
      _c(
        "b-col",
        {
          staticClass: "p-5 mt-7",
          attrs: { cols: "12", "align-self": "stretch" },
        },
        [
          _vm.name == "login"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login()
                    },
                  },
                },
                [
                  _c(
                    "h1",
                    { staticClass: "display-4 mb-3 heading color-inherit" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                  _c(
                    "b-alert",
                    {
                      attrs: { show: _vm.alert.show, variant: _vm.alert.type },
                    },
                    [_vm._v(_vm._s(_vm.alert.message))]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("Email Address:"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "email",
                          type: "email",
                          state: _vm.emailState,
                          disabled: _vm.processing,
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" The email must have '@email.com' ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col", attrs: { for: "password" } },
                            [_vm._v("Password:")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-auto" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "form-text small",
                                  attrs: {
                                    tabindex: "-1",
                                    to: "/forgot-password",
                                  },
                                },
                                [_vm._v("Forgot password?")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "password",
                          type: "password",
                          state: _vm.passwordState,
                          disabled: _vm.processing,
                          required: "",
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [
                          _vm._v(
                            " Min. 8 characters with at least one capital letter, a number and a special character "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        type: "submit",
                        pill: "",
                        block: "",
                        variant: "light",
                        size: "lg",
                        disabled: _vm.processing,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.processing ? "Please wait" : "Continue") +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "small",
                    [
                      _vm._v(" Don't have an account yet? "),
                      _c("router-link", { attrs: { to: "/invitation-code" } }, [
                        _vm._v("Sign up"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.name == "signup"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.signUp()
                    },
                  },
                },
                [
                  _c(
                    "h1",
                    { staticClass: "display-4 mb-3 heading color-inherit" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                  _c(
                    "b-alert",
                    {
                      attrs: { show: _vm.alert.show, variant: _vm.alert.type },
                    },
                    [_vm._v(_vm._s(_vm.alert.message))]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("Email Address:"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "email",
                          type: "email",
                          state: _vm.emailState,
                          disabled: _vm.processing,
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" The email must have '@email.com' ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "password" } }, [
                        _vm._v("Password:"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "password",
                          type: "password",
                          state: _vm.passwordState,
                          disabled: _vm.processing,
                          required: "",
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [
                          _vm._v(
                            " Min. 8 characters with at least one capital letter, a number and a special character "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "cpassword" } }, [
                        _vm._v("Confirm Password:"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "cpassword",
                          type: "password",
                          state: _vm.cpasswordState,
                          disabled: _vm.processing,
                          required: "",
                        },
                        model: {
                          value: _vm.cpassword,
                          callback: function ($$v) {
                            _vm.cpassword = $$v
                          },
                          expression: "cpassword",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" Your password does not match ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        pill: "",
                        block: "",
                        type: "submit",
                        variant: "light",
                        size: "lg",
                        disabled: _vm.processing,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.processing ? "Please Wait" : "Continue") +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "small",
                    [
                      _vm._v(" Already have an account "),
                      _c(
                        "router-link",
                        { attrs: { tabindex: "-1", to: "/login" } },
                        [_vm._v("Log In")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.name == "forgot-password"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.forgotPassword()
                    },
                  },
                },
                [
                  _c(
                    "h1",
                    { staticClass: "display-4 mb-3 heading color-inherit" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm.resetButton == true
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          " Please check your inbox and follow the instructions to reset your password "
                        ),
                      ])
                    : _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                  _c(
                    "b-alert",
                    {
                      attrs: { show: _vm.alert.show, variant: _vm.alert.type },
                    },
                    [_vm._v(_vm._s(_vm.alert.message))]
                  ),
                  _vm.resetButton == false
                    ? _c(
                        "div",
                        { staticClass: "mb-4", attrs: { role: "group" } },
                        [
                          _c("label", { attrs: { for: "email" } }, [
                            _vm._v("Email Address:"),
                          ]),
                          _c("b-form-input", {
                            staticClass: "pl-0",
                            attrs: {
                              id: "email",
                              type: "email",
                              state: _vm.emailState,
                              placeholder: "name@address.com",
                              disabled: _vm.processing,
                              required: "",
                              autocomplete: "false",
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-live-feedback" } },
                            [_vm._v(" The email must have '@email.com' ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resetButton == true
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            pill: "",
                            block: "",
                            to: "/login",
                            variant: "light",
                            size: "lg",
                          },
                        },
                        [_vm._v("Login")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            pill: "",
                            block: "",
                            type: "submit",
                            variant: "light",
                            size: "lg",
                            disabled: _vm.processing,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.processing ? "Please Wait" : "Send")
                          ),
                        ]
                      ),
                  _vm.resetButton == true
                    ? _c("small", [
                        _vm._v(" Didn’t receive the link? "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#", tabindex: "-1" },
                            on: {
                              click: function ($event) {
                                _vm.resetButton = false
                              },
                            },
                          },
                          [_vm._v("Resend")]
                        ),
                      ])
                    : _c(
                        "small",
                        [
                          _vm._v(" Do you remember your password? "),
                          _c(
                            "router-link",
                            { attrs: { tabindex: "-1", to: "/login" } },
                            [_vm._v("Try logging in")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.name == "new-password"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.newPassword()
                    },
                  },
                },
                [
                  _vm.changeButton == true
                    ? [
                        _c(
                          "h1",
                          {
                            staticClass: "display-4 mb-3 heading color-inherit",
                          },
                          [_vm._v(" Successful password reset ")]
                        ),
                        _c("p", [
                          _vm._v(
                            "You can now use your new password to log in to your account"
                          ),
                        ]),
                      ]
                    : [
                        _c(
                          "h1",
                          {
                            staticClass: "display-4 mb-3 heading color-inherit",
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        ),
                        _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                      ],
                  _c(
                    "b-alert",
                    {
                      attrs: { show: _vm.alert.show, variant: _vm.alert.type },
                    },
                    [_vm._v(_vm._s(_vm.alert.message))]
                  ),
                  _vm.changeButton == false
                    ? [
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v("New Password:"),
                            ]),
                            _c("b-form-input", {
                              staticClass: "pl-0",
                              attrs: {
                                id: "password",
                                type: "password",
                                state: _vm.passwordState,
                                disabled: _vm.processing,
                                required: "",
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "input-live-feedback" } },
                              [
                                _vm._v(
                                  " Min. 8 characters with at least one capital letter, a number and a special character "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c("label", { attrs: { for: "cpassword" } }, [
                              _vm._v("Confirm Password:"),
                            ]),
                            _c("b-form-input", {
                              staticClass: "pl-0",
                              attrs: {
                                id: "cpassword",
                                type: "password",
                                state: _vm.cpasswordState,
                                disabled: _vm.processing,
                                required: "",
                              },
                              model: {
                                value: _vm.cpassword,
                                callback: function ($$v) {
                                  _vm.cpassword = $$v
                                },
                                expression: "cpassword",
                              },
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "input-live-feedback" } },
                              [_vm._v(" Your password does not match ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.changeButton == true
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            pill: "",
                            block: "",
                            to: "/login",
                            variant: "light",
                            size: "lg",
                          },
                        },
                        [_vm._v("Login")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            pill: "",
                            block: "",
                            type: "submit",
                            variant: "light",
                            size: "lg",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.processing ? "Please Wait" : "Change Password"
                            )
                          ),
                        ]
                      ),
                ],
                2
              )
            : _vm._e(),
          _vm.name == "invitation-code"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.invitationCode()
                    },
                  },
                },
                [
                  _c(
                    "h1",
                    { staticClass: "display-4 mb-3 color-inherit heading" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "code" } }, [
                        _vm._v("Sign-up code:"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: {
                          id: "code",
                          type: "text",
                          autocomplete: "off",
                          required: "",
                        },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        pill: "",
                        block: "",
                        type: "submit",
                        variant: "light",
                        size: "lg",
                      },
                    },
                    [_vm._v(" Continue ")]
                  ),
                  _c(
                    "small",
                    [
                      _vm._v(" Already have an account "),
                      _c(
                        "router-link",
                        { staticClass: "link", attrs: { to: "/login" } },
                        [_vm._v("Log In")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }