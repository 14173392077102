import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueApollo from "vue-apollo";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

// import { mask } from 'vue-the-mask'
import VueTheMask from "vue-the-mask";
import VuePageTransition from "vue-page-transition";
import vSelect from "vue-select";
import lineClamp from "vue-line-clamp";
import VueResizeObserver from "vue-resize-observer";
import PortalVue from "portal-vue";
import VueMeta from "vue-meta";
import FloatingVue from "floating-vue";

// Segment
import VueSegmentAnalytics from "vue-segment-analytics";

Vue.use(VueSegmentAnalytics, {
  id: process.env.VUE_APP_SEGMENT_WRITEKEY,
});

Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(require("vue-shortkey"));
Vue.use(VuePageTransition);
Vue.use(VueTheMask);
Vue.use(FloatingVue);

Vue.component("v-select", vSelect);

Vue.component("v-icon", () => import("./icon/icon.vue"));
Vue.component("darkmode", () => import("./components/partials/DarkMode.vue"));

import flatPickr from "vue-flatpickr-component";
import {
  BootstrapVue,
  IconsPlugin,
  TooltipPlugin,
  PopoverPlugin,
  LayoutPlugin,
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/theme.scss";
import "flatpickr/dist/flatpickr.min.css";
import "@/assets/js/chart.js";
import moment from "moment";

import "./tailwind.css";
import "floating-vue/dist/style.css";

import "./modules/vee-validate";

Vue.use(LayoutPlugin, { breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl"] });

Vue.config.productionTip = false;
Vue.use(flatPickr);
Vue.use(lineClamp);
Vue.use(VueResizeObserver);

// Vue.directive('mask', mask);

// Install BootstrapVue
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Vee-validate
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required.",
});

// Global
Vue.prototype.moment = moment;

let token = localStorage.getItem("accessToken");
Object.defineProperty(Vue.prototype, "$token", {
  get() {
    return token;
  },
  set(value) {
    token = value;
  },
});

const config = {
  url: process.env.VUE_APP_CHAT_URL,
  region: "us-west-2",
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.VUE_APP_CHAT_API_KEY,
  },
};

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
};

export const appSyncClient = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({
  defaultClient: appSyncClient,
});

Vue.use(VueApollo);

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
