var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["top-bar d-none d-xl-block", _vm.hideSidebar ? "no-sidebar" : ""],
    },
    [
      _c(
        "b-row",
        { staticClass: "pl-3", attrs: { "align-v": "center" } },
        [
          _c(
            "b-col",
            { staticClass: "pl-0 logo-cont", attrs: { cols: "auto" } },
            [
              _c(
                "router-link",
                { staticClass: "logo dark-bg", attrs: { to: "/" } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("b-col", [
            _c("div", { staticClass: "input-group input-group-flush" }),
          ]),
          _c(
            "b-col",
            { staticClass: "pr-1", attrs: { cols: "auto" } },
            [
              _c(
                "router-link",
                { staticClass: "notification", attrs: { to: "/reminders" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "notification",
                      staticStyle: { position: "relative", cursor: "pointer" },
                    },
                    [
                      _c("v-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { name: "reminder", size: "20" },
                      }),
                      _vm.unhandledreminders > 0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "reminder-pill d-flex justify-content-center align-items-center badge badge-pill badge-secondary text-white",
                              style: {
                                right:
                                  _vm.unhandledreminders.toString().length > 1
                                    ? "-6px"
                                    : "-3px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.unhandledreminders) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "router-link",
                { staticClass: "notification", attrs: { to: "/notification" } },
                [
                  _c("v-icon", {
                    class: _vm.notify ? "active" : "",
                    staticStyle: { cursor: "pointer" },
                    attrs: { name: "bell", size: "20" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("AccountNav", {
                attrs: { profileUrl: _vm.profileUrl, name: _vm.nameDisplay },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("vue-page-transition", { attrs: { name: "fade-in-down" } }, [
        _vm.search
          ? _c(
              "div",
              { staticClass: "search-holder" },
              [
                _c(
                  "b-container",
                  [
                    _c(
                      "b-row",
                      { attrs: { "align-h": "center" } },
                      [
                        _c("b-col", { attrs: { cols: "12", md: "10" } }, [
                          _c("ul", { staticClass: "list-unstyled" }, [
                            _c("li", [
                              _c("h4", { staticClass: "bold mb-2" }, [
                                _vm._v("Patient"),
                              ]),
                            ]),
                            _c("li", [_vm._v("Result here")]),
                            _c("li", [_vm._v("Result here")]),
                          ]),
                          _c("ul", { staticClass: "list-unstyled" }, [
                            _c("li", [
                              _c("h4", { staticClass: "bold mb-2" }, [
                                _vm._v("Messages"),
                              ]),
                            ]),
                            _c("li", [_vm._v("Result here")]),
                            _c("li", [_vm._v("Result here")]),
                          ]),
                          _c("ul", { staticClass: "list-unstyled" }, [
                            _c("li", [
                              _c("h4", { staticClass: "bold mb-2" }, [
                                _vm._v("Prescription"),
                              ]),
                            ]),
                            _c("li", [_vm._v("Result here")]),
                            _c("li", [_vm._v("Result here")]),
                          ]),
                          _c("h4", { staticClass: "bold" }, [
                            _vm._v(" Sorry, nothing found for for "),
                            _c("span", [_vm._v("“Lorem Ipsum”")]),
                          ]),
                          _c("h2", { staticClass: "bold" }, [
                            _vm._v(" Showing "),
                            _c("span", { staticClass: "bold" }, [_vm._v("0")]),
                            _vm._v(" results for "),
                            _c("span", [_vm._v("“Lorem Ipsum”")]),
                          ]),
                          _c("hr"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("vue-page-transition", { attrs: { name: "fade-in-up" } }, [
        _vm.search ? _c("div", { staticClass: "overlay-bg" }) : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "viewDosespotNotification",
            centered: "",
            "hide-footer": "",
            size: "xl",
          },
        },
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm.isLoading && !this.dosespotNotificationUrl
                    ? [
                        _c(
                          "b-row",
                          {
                            staticStyle: { height: "350px" },
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("h1", { staticClass: "text-primary" }, [
                                  _vm._v("Loading Dosespot..."),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("iframe", {
                        style: { minHeight: _vm.iframeHeight + "px" },
                        attrs: {
                          src: this.dosespotNotificationUrl,
                          width: "100%",
                          height: "100%",
                          frameborder: "0",
                          scrolling: "yes",
                          allowtransparency: "true",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }