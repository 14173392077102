var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("w-form", {
    attrs: {
      name: this.$route.name,
      title: "Reset your password",
      "sub-title": "Create a strong password",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }