var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hydrated
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.formControl
            ? [
                _c(
                  "b-navbar-brand",
                  { staticClass: "c-logo", attrs: { to: "/" } },
                  [
                    _vm.logoColorSwitch
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon_logo_white.svg"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon_logo.svg"),
                            alt: "",
                          },
                        }),
                  ]
                ),
                _c(
                  "b-container",
                  { staticClass: "landing-page", attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      { staticStyle: { height: "100vh" } },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "4" } },
                          [_c("vue-page-transition", [_c("router-view")], 1)],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [_c("vue-page-transition", [_c("router-view")], 1)],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }