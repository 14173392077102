import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";
import {
  getUnixTime,
  startOfDay,
  endOfDay,
  endOfYesterday,
  startOfTomorrow,
  addDays,
} from "date-fns";

export class ReminderService extends BaseService {
  static async getReminders() {
    try {
      const response = await this.request({ auth: true, v2: true }).get('/doctor/reminder', {
        headers: {
          ...(store.state.globals.currentDoctor.id && {
            doctorid: store.state.globals.currentDoctor.id,
          }),
        },
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPastDueReminders() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/reminder?end=${getUnixTime(endOfYesterday(new Date()))}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getTodayReminders() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/reminder?start=${getUnixTime(
          startOfDay(new Date())
        )}&end=${getUnixTime(endOfDay(new Date()))}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getThisWeekReminders() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/reminder?start=${getUnixTime(
          startOfTomorrow(new Date())
        )}&end=${getUnixTime(endOfDay(addDays(new Date(), 7)))}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async snoozeReminder(reminderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/doctor/reminder/${reminderid}/snooze`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async completeReminder(reminderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/doctor/reminder/${reminderid}/complete`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientReminder(patientid, sort) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/doctor/reminder/patient/${patientid}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addReminder(data) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/doctor/reminder`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateReminder(reminderid, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/doctor/reminder/${reminderid}`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reopenReminder(reminderid, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/doctor/reminder/${reminderid}/reopen`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
