/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from "axios";

import { AuthService } from "@/services/auth.service";
import router from "@/router/index";

export class Http {
  constructor(status) {
    this.isAuth = status && status.auth ? status.auth : false;

    let url;
    if (process.env.VUE_APP_MESSAGING_VERSION === "v2") {
      if (status.v3) {
        url = process.env.VUE_APP_WEBSOCKET_URL;
      } else if (status.v2) {
        url = process.env.VUE_APP_API_URL_V2;
      } else {
        url = process.env.VUE_APP_API_URL;
      }
    } else {
      if (status.v2) {
        url = process.env.VUE_APP_API_URL_V2;
      } else {
        url = process.env.VUE_APP_API_URL;
      }
    }

    this.instance = axios.create({
      baseURL: url,
    });
    NProgress.start();
    return this.init();
  }

  init() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        (request) => {
          request.headers.authorization = AuthService.getBearer();
          NProgress.start();
          return request;
        },
        (error) => {
          NProgress.done();
          return Promise.reject(error);
        }
      );

      this.instance.interceptors.response.use(
        (response) => {
          NProgress.done();
          return response;
        },
        async (error) => {
          const originalRequest = error.config;
          if (
            error.response.status === 401 &&
            (error.response.data.message === "Invalid token" ||
              error.response.data === "Unauthorized")
          ) {
            localStorage.clear();
            router.push({ name: "login" });
            return Promise.reject(error);
          }
          if (
            error.response.status === 401 &&
            (error.response.data.message === "The incoming token has expired" ||
              error.response.data.message === "Expired token") &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;
            try {
              await AuthService.refreshTokens();
              return this.instance(originalRequest);
            } catch (error) {
              if (error.message.includes("401")) {
                return this.instance(originalRequest);
              }
              return Promise.reject(error);
            }
          }
          NProgress.done();
          return Promise.reject(error);
        }
      );
    }
    NProgress.done();
    return this.instance;
  }
}
