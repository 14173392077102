var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.config,
          expression: "config",
        },
      ],
      staticClass: "d-flex flex-column",
      staticStyle: { height: "100%" },
    },
    [
      _vm.isAddNoteVisible
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("p", { staticClass: "text-primary mb-0" }, [
                _vm._v("Add Notes"),
              ]),
              _c(
                "a",
                {
                  staticClass: "text-uppercase",
                  staticStyle: { "letter-spacing": "0.1rem" },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.isAddNoteVisible = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "d-inline-block text-secondary text-uppercase text-underline",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:view-notes-open", false)
                    },
                  },
                },
                [_c("small", [_vm._v("Hide Notes")])]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { pill: "", size: "sm", variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      _vm.isAddNoteVisible = true
                    },
                  },
                },
                [_vm._v(" Add Notes ")]
              ),
            ],
            1
          ),
      _vm.isAddNoteVisible
        ? _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("ValidationObserver", {
                ref: "notesForm",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c("small", { staticClass: "cancel-field-label" }, [
                            _vm._v(" Notes "),
                          ]),
                          _c("ValidationProvider", {
                            attrs: { name: "Notes", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-textarea", {
                                        staticClass: "px-0",
                                        attrs: {
                                          id: "textarea",
                                          rows: "4",
                                          "max-rows": "6",
                                          placeholder: "Write your notes here",
                                        },
                                        model: {
                                          value: _vm.notes,
                                          callback: function ($$v) {
                                            _vm.notes = $$v
                                          },
                                          expression: "notes",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-primary",
                                      attrs: {
                                        pill: "",
                                        variant: "link",
                                        block: "",
                                      },
                                      on: { click: _vm.clearText },
                                    },
                                    [_vm._v(" Clear ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        pill: "",
                                        variant: "primary",
                                        block: "",
                                        disabled: _vm.isProcessing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return handleSubmit(_vm.addNote)
                                        },
                                      },
                                    },
                                    [
                                      _vm.isProcessing
                                        ? _c("b-spinner", {
                                            attrs: { small: "" },
                                          })
                                        : _c("span", [_vm._v("Save")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3112742597
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.notesList.length > 0
        ? _c(
            "div",
            {
              staticClass: "notes-list-container",
              class: { "mt-5": !_vm.isAddNoteVisible },
              staticStyle: { overflow: "scroll", flex: "1 1 auto" },
            },
            _vm._l(_vm.notesList, function (noteInfo, idx) {
              return _c(
                "div",
                {
                  key: noteInfo.id,
                  staticClass: "py-3",
                  class: { "notes-border": idx !== _vm.notesList.length - 1 },
                },
                [
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(_vm._s(noteInfo.notes)),
                  ]),
                  _c("small", { staticStyle: { color: "#333333" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parseDate(noteInfo.timestampcreated)) +
                        " by Dr. " +
                        _vm._s(noteInfo.doctor) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            {
              staticClass: "notes-list-container text-center",
              class: { "mt-5": !_vm.isAddNoteVisible },
            },
            [_vm._v(" Nothing to see here... ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }