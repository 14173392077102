<template>
  <b-row
    style="height: 100vh"
    :class="[
      name == 'signup'
        ? 'bg-secondary'
        : name == 'invitation-code'
        ? 'bg-secondary'
        : 'bg-dark',
    ]"
  >
    <b-col cols="12" class="p-5 mt-7" align-self="stretch">
      <!-- login -->
      <b-form v-if="name == 'login'" @submit.prevent="login()">
        <h1 class="display-4 mb-3 heading color-inherit">{{ title }}</h1>
        <p>{{ subTitle }}</p>
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>

        <div role="group" class="mb-4">
          <label for="email">Email Address:</label>
          <b-form-input
            id="email"
            class="pl-0"
            type="email"
            v-model="email"
            :state="emailState"
            :disabled="processing"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>
        <div role="group" class="mb-4">
          <div class="row" style="align-items: center">
            <label for="password" class="col">Password:</label>
            <div class="col-auto">
              <!-- Help text -->
              <router-link
                tabindex="-1"
                to="/forgot-password"
                class="form-text small"
                >Forgot password?</router-link
              >
            </div>
          </div>
          <b-form-input
            id="password"
            class="pl-0"
            v-model="password"
            type="password"
            :state="passwordState"
            :disabled="processing"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters with at least one capital letter, a number and a
            special character
          </b-form-invalid-feedback>
        </div>

        <b-button
          type="submit"
          pill
          block
          variant="light"
          size="lg"
          class="mb-3"
          :disabled="processing"
        >
          {{ processing ? "Please wait" : "Continue" }}
        </b-button>
        <small>
          Don't have an account yet?
          <router-link to="/invitation-code">Sign up</router-link>
        </small>
      </b-form>

      <!-- signup -->
      <b-form v-if="name == 'signup'" @submit.prevent="signUp()">
        <h1 class="display-4 mb-3 heading color-inherit">{{ title }}</h1>
        <p>{{ subTitle }}</p>
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>

        <div role="group" class="mb-4">
          <label for="email">Email Address:</label>
          <b-form-input
            id="email"
            class="pl-0"
            type="email"
            v-model="email"
            :state="emailState"
            :disabled="processing"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="mb-4">
          <label for="password">Password:</label>
          <b-form-input
            id="password"
            class="pl-0"
            v-model="password"
            type="password"
            :state="passwordState"
            :disabled="processing"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters with at least one capital letter, a number and a
            special character
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="mb-4">
          <label for="cpassword">Confirm Password:</label>
          <b-form-input
            id="cpassword"
            class="pl-0"
            v-model="cpassword"
            type="password"
            :state="cpasswordState"
            :disabled="processing"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Your password does not match
          </b-form-invalid-feedback>
        </div>

        <b-button
          pill
          block
          type="submit"
          variant="light"
          size="lg"
          class="mb-3"
          :disabled="processing"
        >
          {{ processing ? "Please Wait" : "Continue" }}
        </b-button>

        <small>
          Already have an account
          <router-link tabindex="-1" to="/login">Log In</router-link>
        </small>
      </b-form>

      <!-- forgot password -->
      <b-form
        v-if="name == 'forgot-password'"
        @submit.prevent="forgotPassword()"
      >
        <h1 class="display-4 mb-3 heading color-inherit">{{ title }}</h1>
        <p v-if="resetButton == true" class="text-muted">
          Please check your inbox and follow the instructions to reset your
          password
        </p>
        <p v-else>{{ subTitle }}</p>
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>

        <div role="group" class="mb-4" v-if="resetButton == false">
          <label for="email">Email Address:</label>

          <b-form-input
            id="email"
            class="pl-0"
            v-model="email"
            type="email"
            :state="emailState"
            placeholder="name@address.com"
            :disabled="processing"
            required
            autocomplete="false"
          ></b-form-input>

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>

        <b-button
          v-if="resetButton == true"
          pill
          block
          to="/login"
          variant="light"
          size="lg"
          class="mb-3"
          >Login</b-button
        >
        <b-button
          v-else
          pill
          block
          type="submit"
          variant="light"
          size="lg"
          class="mb-3"
          :disabled="processing"
          >{{ processing ? "Please Wait" : "Send" }}</b-button
        >

        <small v-if="resetButton == true">
          Didn’t receive the link?
          <a href="#" tabindex="-1" @click="resetButton = false">Resend</a>
        </small>
        <small v-else>
          Do you remember your password?
          <router-link tabindex="-1" to="/login">Try logging in</router-link>
        </small>
      </b-form>

      <b-form v-if="name == 'new-password'" @submit.prevent="newPassword()">
        <template v-if="changeButton == true">
          <h1 class="display-4 mb-3 heading color-inherit">
            Successful password reset
          </h1>
          <p>You can now use your new password to log in to your account</p>
        </template>
        <template v-else>
          <h1 class="display-4 mb-3 heading color-inherit">{{ title }}</h1>
          <p>{{ subTitle }}</p>
        </template>

        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>

        <template v-if="changeButton == false">
          <div role="group" class="mb-4">
            <label for="password">New Password:</label>
            <b-form-input
              id="password"
              class="pl-0"
              v-model="password"
              type="password"
              :state="passwordState"
              :disabled="processing"
              required
            ></b-form-input>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Min. 8 characters with at least one capital letter, a number and a
              special character
            </b-form-invalid-feedback>
          </div>

          <div role="group" class="mb-4">
            <label for="cpassword">Confirm Password:</label>
            <b-form-input
              id="cpassword"
              class="pl-0"
              v-model="cpassword"
              type="password"
              :state="cpasswordState"
              :disabled="processing"
              required
            ></b-form-input>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Your password does not match
            </b-form-invalid-feedback>
          </div>
        </template>

        <b-button
          v-if="changeButton == true"
          pill
          block
          to="/login"
          variant="light"
          size="lg"
          class="mb-3"
          >Login</b-button
        >
        <b-button
          v-else
          pill
          block
          type="submit"
          variant="light"
          size="lg"
          class="mb-3"
          >{{ processing ? "Please Wait" : "Change Password" }}</b-button
        >
      </b-form>

      <b-form
        v-if="name == 'invitation-code'"
        @submit.prevent="invitationCode()"
      >
        <h1 class="display-4 mb-3 color-inherit heading">{{ title }}</h1>
        <p>{{ subTitle }}</p>

        <div role="group" class="mb-4">
          <label for="code">Sign-up code:</label>
          <b-form-input
            id="code"
            class="pl-0"
            type="text"
            v-model="code"
            autocomplete="off"
            required
          ></b-form-input>
        </div>

        <b-button
          pill
          block
          type="submit"
          variant="light"
          size="lg"
          class="mb-3"
        >
          Continue
        </b-button>

        <small>
          Already have an account
          <router-link to="/login" class="link">Log In</router-link>
        </small>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { io } from "socket.io-client";

import { AuthService } from "@/services/auth.service";
import { UsersService } from "@/services/users.service";

import formValidation from "../form/mixins";
import VueJwtDecode from "vue-jwt-decode";

import { isValidEmail } from "@/utils/string";

export default {
  mixins: [formValidation],
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
  },
  data() {
    return {
      code: "",
      email: "",
      password: "",
      cpassword: "",
      resetButton: false,
      changeButton: false,
      processing: false,
      error: "",
      alert: {
        show: false,
        type: "info",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      invitecode: "user/getInviteCode",
    }),
  },
  methods: {
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },

    async login() {
      try {
        this.processing = true;
        this.alert.show = false;
        const { data: auth } = await AuthService.login({
          email: this.email,
          password: this.password,
        });
        this.error = "";

        window.analytics.identify(this.email, {
          email: this.email,
        });

        if (window.Intercom) {
          window.intercomSettings.email = this.email;
        }

        const token = VueJwtDecode.decode(localStorage.getItem("accessToken"));
        if (token["custom:role"] != "DOCTOR") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("token");
          this.alert.type = "warning";
          this.alert.message = "Only doctors can log in to this portal.";
          this.alert.show = true;
          return;
        }

        if (auth.onboardingstatus === "PENDING") {
          if (auth.onboardingstep === 1) {
            this.$router.push("/personal-details");
          } else if (
            auth.onboardingstep === 2 ||
            auth.onboardingstep === 2.1 ||
            auth.onboardingstep === 2.2
          ) {
            this.$router.push("/upload-document");
          } else if (auth.onboardingstep === 3) {
            this.$router.push("/summary");
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/";
        }
        await this.$store.dispatch("user/getUser");
      } catch (error) {
        if (error) {
          this.error =
            error.status === 404
              ? "User with same email not found"
              : error.message;
          this.alertMessage("warning", this.error);
        }
      } finally {
        this.processing = false;
      }
    },

    async signUp() {
      try {
        this.processing = true;
        this.alert.show = false;
        if (this.password !== this.cpassword) {
          return this.alertMessage("warning", "Password did not match");
        }

        if (!isValidEmail(this.email)) {
          return this.alertMessage("warning", "Invalid email address");
        }

        let data = {
          email: this.email,
          password: this.password,
          role: "DOCTOR",
          invitecode: this.invitecode,
        };

        const { data: auth } = await AuthService.signup(data);
        AuthService.setBearer(auth.idToken);

        this.$router.push("/personal-details");
      } catch (error) {
        this.error = error.message;
        this.alertMessage("warning", this.error);
        if (this.error === "Invalid sign-up code") {
          setTimeout(() => {
            this.$router.push("/invitation-code");
          }, 2000);
          return;
        }

        if (error.code === "INVALID_EMAIL_DOMAIN") {
          this.error = error.message;
        }
      } finally {
        this.processing = false;
      }
    },

    invitationCode() {
      this.$store.commit("user/SET_INVITE_CODE", this.code);
      this.$router.push("/signup");
    },

    async forgotPassword() {
      try {
        this.processing = true;
        this.alert.show = false;
        await UsersService.forgotPassword(this.email);
        this.resetButton = true;
      } catch (error) {
        console.log(error.message);
        if (error.message) {
          return this.alertMessage("warning", error.message);
        }
      } finally {
        this.processing = false;
      }
    },

    async newPassword() {
      try {
        this.processing = true;
        this.alert.show = false;
        if (this.password !== this.cpassword) {
          return this.alertMessage("warning", "Password did not match");
        }

        var jwttoken = this.$route.query.v;
        var code = this.$route.query.c;

        var decoded = VueJwtDecode.decode(jwttoken);

        var confirmpassword = {
          email: decoded.username,
          code: code,
          password: this.password,
        };

        await UsersService.confirmPassword(confirmpassword);
        this.changeButton = true;
      } catch (error) {
        if (error.message) {
          return this.alertMessage("warning", error.message);
        }
      } finally {
        this.processing = false;
      }
    },
    connectToSocket() {
      if (localStorage.getItem("accessToken")) {
        const socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
          auth: {
            token: localStorage.getItem("accessToken"),
          },
          transports: ["websocket", "polling"],
        });

        this.$store.dispatch("socket/connectToSocket", socket);

        socket.on("connect", () => {
          console.log("Connected to server");
        });

        socket.on("connect_error", (err) => {
          console.log(err);
          socket.io.opts.transports = ["polling", "websocket"];
        });

        socket.on("token_expired", async (err) => {
          console.log(err);
          await AuthService.logout();
          this.$store.dispatch("socket/closeSocketConnection");
        });

        socket.on("request_error", (err) => {
          console.log(err);
        });

        socket.on("validation_error", (err) => {
          console.log(err);
        });

        socket.on("message", (data) => {
          if (
            ["patient-profile", "intakehistory", "order"].includes(
              this.$router.currentRoute.name
            ) &&
            data.userid === this.$route.params.id
          ) {
            this.$store.dispatch("message/incrementPatientUnreadMessageCount");
          }
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      const token = VueJwtDecode.decode(localStorage.getItem("accessToken"));
      if (token["custom:role"] != "DOCTOR") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("token");
        next({ name: "login" });
      }

      return this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
// @media (max-width: 280px) {
//   .c-margin {
//     &.signup,
//     &.invite-code,
//     &.new-password {
//       margin-top: calc(100vh / 8);
//     }

//     &.login {
//       margin-top: calc(100vh / 6);
//     }
//   }
// }

// @media (min-width: 320px) {
//   .c-margin {
//     &.signup,
//     &.invite-code,
//     &.new-password {
//       margin-top: calc(100vh / 10);
//     }

//     &.login {
//       margin-top: calc(100vh / 5);
//     }

//     &.forgot-password {
//       margin-top: calc(100vh / 6);
//     }
//   }
// }

// @media (min-width: 420px) {
//   .c-margin {
//     &.invite-code,
//     &.new-password {
//       margin-top: calc(100vh / 4);
//     }

//     &.signup {
//       margin-top: calc(100vh / 7);
//     }

//     &.login {
//       margin-top: calc(100vh / 5);
//     }

//     &.forgot-password {
//       margin-top: calc(100vh / 3.5);
//     }
//   }
// }

// @media (min-width: 769px) {
//   .c-margin {
//     padding-left: 5em;
//   }
// }

// @media (max-width: 768px) {
//   .c-margin {
//     justify-content: center;
//   }
// }
</style>
