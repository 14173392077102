import { PatientService } from "@/services/patient.service.js";

export default {
  namespaced: true,
  state: {
    patients: [],
    patientInfo: {},
    patientVerification: {},
    healthQuestions: [],
    followupQuestions: [],
    healthFlagQuestions: {},
    followupSchedule: [],
  },
  mutations: {
    CLEAR_VERIFICATION(state) {
      state.patientVerification = {};
    },
    CLEAR_PATIENT_INFO(state) {
      state.patientInfo = {};
    },
    SET_PATIENTS(state, patients) {
      state.patients = patients;
    },
    SET_HEALTH_QUESTIONS(state, healthQuestions) {
      state.healthQuestions = healthQuestions;
    },
    SET_FOLLOWUP_QUESTIONS(state, followupQuestions) {
      state.followupQuestions = followupQuestions;
    },
    SET_HEALTH_FLAG_QUESTIONS(state, healthFlagQuestions) {
      state.healthFlagQuestions = healthFlagQuestions;
    },
    SET_PATIENT_INFO(state, patientData) {
      state.patientInfo = {
        defaultaddress: {},
      };
      state.patientInfo = patientData;
    },
    SET_PATIENT_VERIFICATION(state, patientData) {
      state.patientVerification = patientData;
    },
    SET_FOLLOWUP_SCHEDULE(state, followupSchedule) {
      state.followupSchedule = followupSchedule;
    },
  },
  getters: {
    getPatients(state) {
      return state.patients;
    },
    getPatientInfo(state) {
      return state.patientInfo;
    },
    getHealthQuestions(state) {
      return state.healthQuestions;
    },
    getPatientVerification(state) {
      return state.patientVerification;
    },
  },
  actions: {
    getPatients({ commit }, nextpage) {
      return PatientService.getPatients(nextpage)
        .then(({ data }) => {
          commit("SET_PATIENTS", data);
          return data;
        })
        .catch((error) => error);
    },
    getPatientVerification({ commit }, patientId) {
      return PatientService.getPatientVerification(patientId)
        .then(({ data }) => {
          commit("SET_PATIENT_VERIFICATION", {
            identificationURL: data.identificationURL,
            identificationFileType: data.identificationFileType,
            photoURL: data.photoURL,
            photoFileType: data.photoFileType,
          });
        })
        .catch((error) => error);
    },
    searchPatient({ commit }, search) {
      return PatientService.searchPatient(search)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => error);
    },
    getHealthQuestions({ commit, state }, patientId) {
      commit("SET_PATIENT_INFO", {
        ...state.patientInfo,
        questions: [],
        followupquestions: [],
      });
      return PatientService.getHealthQuestions(patientId)
        .then(({ data }) => {
          let tempData = [];
          for (let i = 0; i < data.medicalquestions.length; i++) {
            if (data.medicalquestions[i].answer) {
              tempData.push(data.medicalquestions[i]);
            }
          }
          let tempDataFollowUp = [];
          for (let i = 0; i < data.followupquestions.length; i++) {
            // if (data.followupquestions[i].answer) {
            tempDataFollowUp.push(data.followupquestions[i]);
            // }
          }
          if (tempDataFollowUp.length == 0) {
            tempDataFollowUp = undefined;
          }
          // console.log(tempDataFollowUp)
          commit("SET_HEALTH_QUESTIONS", tempData);
          commit("SET_FOLLOWUP_QUESTIONS", tempDataFollowUp);
          commit("SET_PATIENT_INFO", {
            ...state.patientInfo,
            questions: tempData,
            followupquestions: tempDataFollowUp,
          });
          return {
            medicalquestions: tempData,
            followupquestions: tempDataFollowUp,
          };
        })
        .catch((error) => error);
    },
    async getPatientInfo(
      { commit, dispatch, rootState },
      { patientId, isRefetch }
    ) {
      let recentlyViewPatients = [];
      if (!isRefetch) {
        commit("SET_PATIENT_INFO", {});
      }
      localStorage.setItem("patientId", patientId);
      if (JSON.parse(localStorage.getItem("recentlyViewedPatients"))) {
        recentlyViewPatients = JSON.parse(
          localStorage.getItem("recentlyViewedPatients")
        );
      }
      return PatientService.getPatient(patientId)
        .then(async ({ data }) => {
          // recentlyViewPatients.push(data);
          let checkExists = recentlyViewPatients.find((x) => x.id === data.id);

          if (!checkExists) {
            recentlyViewPatients.push({
              id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              profileUrl: data.profileUrl,
            });
          } else {
            let existindex = recentlyViewPatients.indexOf(checkExists);
            recentlyViewPatients.splice(existindex, 1);
            recentlyViewPatients.push({
              id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              profileUrl: data.profileUrl,
            });
          }
          if (!rootState.globals.currentDoctor.id) {
            localStorage.setItem(
              "recentlyViewedPatients",
              JSON.stringify(recentlyViewPatients)
            );
          }
          dispatch("getHealthQuestions", patientId);
          commit("SET_PATIENT_INFO", data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    async getPatientInfoV2(
      { commit, dispatch, rootState },
      { patientId, isRefetch }
    ) {
      let recentlyViewPatients = [];
      if (!isRefetch) {
        commit("SET_PATIENT_INFO", {});
      }
      localStorage.setItem("patientId", patientId);
      if (JSON.parse(localStorage.getItem("recentlyViewedPatients"))) {
        recentlyViewPatients = JSON.parse(
          localStorage.getItem("recentlyViewedPatients")
        );
      }
      return PatientService.getPatientMin(patientId)
        .then(async ({ data }) => {
          // recentlyViewPatients.push(data);
          let checkExists = recentlyViewPatients.find((x) => x.id === data.id);

          if (!checkExists) {
            recentlyViewPatients.push({
              id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              profileUrl: data.profileUrl,
            });
          } else {
            let existindex = recentlyViewPatients.indexOf(checkExists);
            recentlyViewPatients.splice(existindex, 1);
            recentlyViewPatients.push({
              id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              profileUrl: data.profileUrl,
            });
          }
          if (!rootState.globals.currentDoctor.id) {
            localStorage.setItem(
              "recentlyViewedPatients",
              JSON.stringify(recentlyViewPatients)
            );
          }
          dispatch("getHealthQuestions", patientId);
          commit("SET_PATIENT_INFO", data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    getPatientFollowupSchedule({ commit }, patientid) {
      return PatientService.getPatientFollowupSchedule(patientid)
        .then(({ data }) => {
          commit("SET_FOLLOWUP_SCHEDULE", data);
          return data;
        })
        .catch((error) => error);
    },
    updatePatientFollowupSchedule(
      { dispatch },
      { patientid, patientfollowupid, data }
    ) {
      return PatientService.updatePatientFollowupSchedule(
        patientid,
        patientfollowupid,
        data
      )
        .then(({ data }) => {
          dispatch("getPatientFollowupSchedule", patientid);
          return data;
        })
        .catch((error) => error);
    },
    schedulePatientFollowup({ dispatch }, { patientid, data, bvToast }) {
      return PatientService.schedulePatientFollowup(patientid, data)
        .then(({ data }) => {
          dispatch("getPatientFollowupSchedule", patientid);
          return data;
        })
        .catch((error) => {
          // bvToast.toast(error.message, {
          //   title: "Something went wrong",
          //   variant: "danger",
          //   solid: true,
          //   toaster: "b-toaster-bottom-center",
          // });
          return error;
        });
    },
    deletePatientFollowupSchedule(
      { dispatch },
      { patientid, patientfollowupid }
    ) {
      return PatientService.deletePatientFollowupSchedule(
        patientid,
        patientfollowupid
      )
        .then(({ data }) => {
          dispatch("getPatientFollowupSchedule", patientid);
          return data;
        })
        .catch((error) => error);
    },
    async updatePatient({ dispatch }, { patientId, data }) {
      try {
        const { data: response } = await PatientService.updatePatient(
          patientId,
          data
        );
        await dispatch("getPatientInfo", { patientId, isRefetch: true });
        return response;
      } catch (error) {
        throw error;
      }
    },
    async acceptPatient({ commit }, patientid) {
      try {
        await PatientService.acceptPatient(patientid);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    // Patients Page
    async getPatientStatusCount() {
      try {
        const { data } = await PatientService.getPatientStatusCount();

        return data;
      } catch (error) {
        throw error;
      }
    },
    async searchPatients({ commit }, { status, filter }) {
      try {
        const { data: response } = await PatientService.searchPatients(
          status,
          filter
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getAllPatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getAllPatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getActivePatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getActivePatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getNeedsReplyPatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getNeedsReplyPatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getIgnoredPatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getIgnoredPatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getCancelledPatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getCancelledPatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async getCoveredPatients({ commit }, nextpage) {
      try {
        const { data: response } = await PatientService.getCoveredPatients(
          nextpage
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
};
