import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class DoctorService extends BaseService {
  static async getDoctors() {
    try {
      const response = await this.request({ auth: true }).get(
        "superdoctor/doctor"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
