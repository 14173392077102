import { AccountService } from "@/services/account.service";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async getReassignmentRequest({ commit }, { showActive }) {
      try {
        const { data } = await AccountService.getReassignmentRequest({
          showActive,
        });
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async requestDoctorReassignment({ dispatch }, { data }) {
      try {
        const response = await AccountService.requestDoctorReassignment(data);
        return response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async editDoctorReassignment({ dispatch }, { doctorrequestid, data }) {
      try {
        const response = await AccountService.editDoctorReassignment(
          doctorrequestid,
          data
        );
        return response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async cancelDoctorReassignment({ dispatch }, doctorrequestid) {
      try {
        const response = await AccountService.cancelDoctorReassignment(
          doctorrequestid
        );
        return response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async removeDoctorReassignment({ dispatch }, doctorrequestid) {
      try {
        const response = await AccountService.removeDoctorReassignment(
          doctorrequestid
        );
        return response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
