var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    [
      _c("span", [_vm._v("©Winona " + _vm._s(_vm.getYear))]),
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/contact-us" } }, [
              _vm._v("Contact Us"),
            ]),
          ],
          1
        ),
        _c("li", [
          _c(
            "a",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.terms",
                  modifiers: { terms: true },
                },
              ],
              attrs: { href: "#" },
            },
            [_vm._v("Terms & Conditions")]
          ),
        ]),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "terms",
            "hide-footer": "",
            size: "lg",
            title: "Terms & Conditions",
            scrollable: "",
          },
        },
        [_c("terms")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }