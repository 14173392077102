import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class UsersService extends BaseService {
  static async getCurrent() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        "doctor/user",
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async emailVerification(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/emailverification"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateUser(data) {
    try {
      const response = await this.request({ auth: true }).put("user", data);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateUserEmail(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/email",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadAvatar(avatar) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/avatar",
        avatar
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadPhoto(photo) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/photo",
        photo
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadIdentification(identification) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/identification",
        identification
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async forgotPassword(email) {
    try {
      const response = await this.request({ auth: false }).post(
        "password/forgot",
        { email }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async confirmPassword(data) {
    try {
      const response = await this.request({ auth: false }).post(
        "password/confirm",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async changePassword(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "password/change",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
