import { UpsellService } from "@/services/upsell.service";

export default {
  namespaced: true,
  actions: {
    async denyUpsell({ dispatch }, { upsellid }) {
      try {
        await UpsellService.denyUpsellProduct(upsellid);
        dispatch("dashboard/getDashboardData", null, { root: true });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
