import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class PatientService extends BaseService {
  static async getPatientsV2(
    type = "da-all",
    page = "",
    search = "",
    sortDirection = "DESC",
    sortField = "lastupdateactivity"
  ) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `doctor/patientDashboard/${type}`,
        {
          page,
          search,
          sortDirection,
          sortField,
        },
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPatient(search) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/search?contains=${search}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatient(patientId) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `patient/${patientId}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientMin(patientId) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `patient/${patientId}/min`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientVerification(patientId) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `doctor/patientVerification/${patientId}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHealthQuestions(patientId) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `doctor/patientHealthQuestion/${patientId}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getIncomingPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/incoming?nextpage=${nextpage}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePatient(patientId, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `patient/${patientId}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async acceptPatient(patientId) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `doctor/patientVerification/${patientId}/accept`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async verifyPatient(patientId, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `doctor/patientVerification/${patientId}/verify`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelPatient(patientId, data) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/patient/${patientId}/cancel`,
        { data }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientFollowupSchedule(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `doctor/patientFollowup/${patientid}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );

      for (let rec of response.data) {
        if (rec.followupstatus === "FOLLOWUP") {
          rec.followupstatus = "SCHEDULED";
        }
      }

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePatientFollowupSchedule(
    patientId,
    patientfollowupid,
    data
  ) {
    try {
      const response = await this.request({ auth: true }).put(
        `patient/${patientId}/followupschedule/${patientfollowupid}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async schedulePatientFollowup(patientId, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `patient/${patientId}/followupschedule`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deletePatientFollowupSchedule(patientId, patientFollowupId) {
    try {
      const response = await this.request({ auth: true, v2: true }).delete(
        `doctor/patientFollowup/${patientId}/${patientFollowupId}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  // Patients Page
  static async getPatientStatusCount() {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `doctor/patientDashboard/counts`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPatients(status, filter) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/${status}?search=${filter}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAllPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-all?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getActivePatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-active?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNeedsReplyPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-needsreply?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getIgnoredPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-ignored?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getCancelledPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-cancelled?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getCoveredPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/status/da-covered?nextpage=${nextpage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
