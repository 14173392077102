<template>
  <w-form
    :name="this.$route.name"
    title="Good to see you."
    sub-title="Please sign in to continue"
  />
</template>

<script>
import wForm from "@/components/form/Form";

export default {
  components: {
    wForm,
  },
  mounted() {
    window.analytics.page();
  },
};
</script>
