<template>
  <!-- Dropdown -->
  <b-dropdown variant="link" block right no-caret ref="dropdown">
    <template v-slot:button-content>
      <!-- Toggle -->
      <span
        v-if="currentDoctor.id"
        href="#"
        id="sidebarIcon"
        class="dropdown-toggle d-letter-spacing"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="d-none d-xl-block mr-4">
          <small class="mb-0">Viewing</small>
          <h3 class="mb-0 bold">
            <template> {{ currentDoctor.name }} </template>
          </h3>
        </div>
        <b-icon icon="caret-down-fill" font-scale="0.75"></b-icon>
      </span>
      <span
        v-else
        href="#"
        id="sidebarIcon"
        class="dropdown-toggle d-letter-spacing"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <b-avatar
          v-if="profileUrl"
          variant="secondary"
          :text="name.charAt(0)"
          :src="profileUrl"
          class="mr-3"
        ></b-avatar>
        <b-skeleton animation="fade" v-else type="avatar"></b-skeleton>
        <div class="account-body d-none d-xl-block mr-4">
          <h3 class="mb-0 bold">
            <template v-if="!name">
              <b-skeleton animation="fade" width="140px"></b-skeleton>
            </template>
            <template v-else> Dr. {{ name }} </template>
          </h3>
          <b-skeleton animation="fade" v-if="!name" width="100px"></b-skeleton>
          <small v-else class="mb-0">Primary Care Physician</small>
        </div>
        <b-icon icon="caret-down-fill" font-scale="0.75"></b-icon>
      </span>
    </template>
    <!-- Menu -->
    <div v-if="currentDoctor.id">
      <b-dropdown-item @click.prevent="onLogoutDoctor">
        Logout doctor
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item
        class="d-xl-none"
        v-for="(list, i) in navList"
        :key="i + '3'"
        :to="'/' + list.link"
        exact
        exact-active-class="active"
      >
        {{ list.name }}
      </b-dropdown-item>

      <b-dropdown-divider class="d-xl-none"></b-dropdown-divider>

      <b-dropdown-item
        v-for="(a, i) in accountList"
        :key="i + '1'"
        :to="'/' + a.link"
        exact
        exact-active-class="active"
      >
        {{ a.name }}
      </b-dropdown-item>

      <b-dropdown-item @click="redirectToHelpCenter">
        Help Center
      </b-dropdown-item>

      <b-dropdown-text>
        <darkmode />
      </b-dropdown-text>

      <b-dropdown-divider class="d-xl-none"></b-dropdown-divider>

      <b-dropdown-item @click="logOut">Logout</b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import { AuthService } from "@/services/auth.service";

import { NAV_LIST } from "@/constants/side-nav";

export default {
  name: "account-nav",
  props: {
    name: String,
    profileUrl: String,
  },
  data() {
    return {
      theme: false,
      navList: NAV_LIST,
      accountList: [
        {
          name: "Account Details",
          link: "account",
        },
        {
          name: "Security Settings",
          link: "security",
        },
        {
          name: "Uploaded Documents",
          link: "document",
        },
        {
          name: "Out of Office",
          link: "out-of-office",
        },
      ],
    };
  },
  computed: {
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  methods: {
    async logOut() {
      await AuthService.logout();
      this.$store.dispatch("socket/closeSocketConnection");
    },
    onLogoutDoctor() {
      this.$store.dispatch("globals/setCurrentDoctor", { id: "", name: "" });
      window.location.href = "/";
    },
    redirectToHelpCenter() {
      window.open("https://doctor-help.bywinona.com", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-dark,
.bg-dark,
.dark-bg {
  .dropdown-toggle:hover {
    color: inherit !important;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
  color: $primary;

  &:hover {
    color: $primary;
  }

  &::after {
    margin-left: 15px;
    display: none;
  }

  .account-body {
    margin-left: 5px;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .bi-caret-down-fill {
    color: $primary !important;
  }
}
</style>
