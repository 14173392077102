<template>
  <div class="d-flex flex-column" style="height: 100%" v-click-outside="config">
    <div
      v-if="isAddNoteVisible"
      class="d-flex justify-content-between align-items-center"
    >
      <p class="text-primary mb-0">Add Notes</p>
      <a
        href="#"
        @click="isAddNoteVisible = false"
        class="text-uppercase"
        style="letter-spacing: 0.1rem"
      >
        Close
      </a>
    </div>
    <div v-else class="d-flex justify-content-between align-items-center">
      <a
        @click="$emit('update:view-notes-open', false)"
        class="d-inline-block text-secondary text-uppercase text-underline"
        style="cursor: pointer"
      >
        <small>Hide Notes</small>
      </a>
      <b-button
        pill
        size="sm"
        variant="outline-primary"
        class="text-uppercase"
        @click="isAddNoteVisible = true"
      >
        Add Notes
      </b-button>
    </div>
    <div class="my-4" v-if="isAddNoteVisible">
      <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
        <small class="cancel-field-label"> Notes </small>
        <ValidationProvider name="Notes" rules="required" v-slot="{ errors }">
          <b-form-textarea
            id="textarea"
            class="px-0"
            v-model="notes"
            rows="4"
            max-rows="6"
            placeholder="Write your notes here"
          ></b-form-textarea>
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
        <b-row class="mt-4">
          <b-col cols="12" md="6">
            <b-button
              pill
              variant="link"
              block
              class="text-primary"
              @click="clearText"
            >
              Clear
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              pill
              variant="primary"
              block
              @click="handleSubmit(addNote)"
              :disabled="isProcessing"
            >
              <b-spinner v-if="isProcessing" small></b-spinner>
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>
    <div
      class="notes-list-container"
      :class="{ 'mt-5': !isAddNoteVisible }"
      style="overflow: scroll; flex: 1 1 auto"
      v-if="notesList.length > 0"
    >
      <div
        class="py-3"
        :class="{ 'notes-border': idx !== notesList.length - 1 }"
        v-for="(noteInfo, idx) in notesList"
        :key="noteInfo.id"
      >
        <p class="mb-1">{{ noteInfo.notes }}</p>
        <small style="color: #333333">
          {{ parseDate(noteInfo.timestampcreated) }} by Dr.
          {{ noteInfo.doctor }}
        </small>
      </div>
    </div>
    <div
      class="notes-list-container text-center"
      :class="{ 'mt-5': !isAddNoteVisible }"
      v-else
    >
      Nothing to see here...
    </div>
  </div>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
import vClickOutside from "v-click-outside";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    viewNotesOpen: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isAddNoteVisible: false,
      isProcessing: false,
      notes: "",
      vcoConfig: {
        handler: this.onClickOutside,
        isActive: false,
      },
    };
  },
  computed: {
    notesList() {
      return this.$store.state.notes.notesList;
    },
    config() {
      return { ...this.vcoConfig };
    },
  },
  watch: {
    viewNotesOpen(val) {
      if (val) {
        this.vcoConfig.isActive = true;
      } else {
        this.vcoConfig.isActive = false;
      }
    },
  },
  methods: {
    parseDate(date) {
      return format(fromUnixTime(date), "MM/dd/yyyy hh:mmaaa");
    },
    async addNote() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("notes/addNote", {
          patientid: this.$route.params.id,
          notes: this.notes,
        });
        this.notes = "";
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$refs.notesForm.reset();
        });
      }
    },
    clearText() {
      this.notes = "";
    },
    onClickOutside() {
      this.$emit("update:view-notes-open", false);
    },
  },
};
</script>

<style scoped>
.notes-list-container {
  background: #f9fbfb;
  padding: 0.5rem 1.5rem;
}

.notes-border {
  border-bottom: 1px solid #e4e4e4;
}
</style>
