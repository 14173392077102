<template>
  <div class="position-relative" v-if="!processing">
    <portal-target name="notes"></portal-target>
    <portal-target name="pricing"></portal-target>
    <!-- sidebar trigger viewing of profile pic and name when in mobile view just to make sure this is the same on top bar -->
    <sidebar :profileUrl="userImg" :name="currentUser" />
    <top-bar
      :profileUrl="userImg"
      :name="currentUser"
      :class="hideSidebar ? 'dark-bg' : ''"
    />
    <div
      :class="[
        hideSidebar
          ? 'is-hide'
          : this.$route.name == 'messages'
          ? ''
          : 'tw-pl-4 tw-pr-4 md:tw-pl-7 md:tw-pr-7',
        'main-content tw-relative',
      ]"
    >
      <vue-page-transition>
        <router-view :doctor-name="currentUser" />
        <router-view name="modal" />
      </vue-page-transition>
    </div>

    <!-- Not Fit -->
    <!-- not fit for treatment -->
    <b-modal
      id="notfit"
      hide-header
      hide-header-close
      centered
      hide-footer
      size="md"
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <v-icon name="icon-logo" size="30"></v-icon>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading">
          Are you sure this patient is not fit for treatment?
        </h2>
        <b-row class="text-center" align-h="center" no-gutters>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="toggleModal('notfit')"
              variant="outline-primary"
              >confirm</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              @click="$bvModal.hide('notfit')"
              variant="primary"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- INTAKE HISTORY MODAL -->
    <b-modal
      id="intake"
      header-class="intake-header position-relative"
      :footer-class="`position-absolute view-notes-modal d-block shadow-sm ${
        isViewNotesOpen ? 'show-modal' : ''
      }`"
      size="xl"
      scrollable
      centered
    >
      <template v-slot:modal-header="{ close }">
        <!-- name of patient -->
        <b-container fluid>
          <b-row class="p-3 mt-3">
            <b-col>
              <h3 class="mb-0 bold" style="letter-spacing: 0.5px">
                {{ `${patientInfo.firstname} ${patientInfo.lastname}` }}
                <a
                  @click="isViewNotesOpen = true"
                  class="
                    d-inline-block
                    ml-3
                    text-secondary text-uppercase text-underline
                  "
                  style="cursor: pointer"
                >
                  <small>View Notes</small>
                </a>
              </h3>
            </b-col>
            <b-col>
              <h3 class="mb-0 text-left bold" style="letter-spacing: 0.5px">
                {{ patientInfo.patientno }}
              </h3>
            </b-col>
          </b-row>
        </b-container>
        <!-- Emulate built in modal header close button action -->
        <b-button
          size="sm"
          variant="link"
          @click="close()"
          style="margin-top: 10px"
        >
          <b-icon icon="x-circle-fill" variant="secondary"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer>
        <ModalBodyViewNotes :view-notes-open.sync="isViewNotesOpen" />
      </template>
      <b-row class="p-4">
        <b-col
          cols="12"
          class="mb-0"
          v-if="patientInfo.followupquestions != undefined"
        >
          <b-row align-v="center" class="mb-0">
            <b-col
              cols="12"
              v-for="(followups, index) in patientInfo.followupquestions"
              :key="index"
            >
              <h3 class="bold mb-4" style="letter-spacing: 0.5px">
                {{ followups.type === "1YEAR" ? "1YR" : "10WK" }} Follow-Up
                Questions |
                {{
                  parseDate(followups.timestampanswered, "MM/dd/yyyy hh:mmaaa")
                }}
              </h3>

              <div class="health-question-wrapper">
                <div class="health-question">
                  <div
                    class="hq-list"
                    v-for="(
                      questionitems, indexquestions
                    ) in followups.followupanswer"
                    :key="indexquestions"
                  >
                    <div class="tw-pb-6">
                      <label class="intake-label"
                        >{{ indexquestions + 1 }}. {{ questionitems.question }}
                      </label>
                      <p class="pl-0 intake-input">
                        {{
                          questionitems.answer.replace(/(\r\n|\n|\r)/gm, "\r\n")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <b-row>
                <b-col
                  v-for="(
                    questionitems, indexquestions
                  ) in followups.followupanswer"
                  :key="indexquestions"
                  cols="12"
                  md="6"
                >
                  <div role="group" class="mb-4">
                    <label class="intake-label"
                      >{{ indexquestions + 1 }}. {{ questionitems.question }}
                    </label>

                    <br />

                    <b-form-textarea
                      disabled
                      max-rows="15"
                      class="pl-0 intake-input"
                      type="text"
                      style="overflow: hidden"
                      :value="
                        questionitems.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')
                      "
                    ></b-form-textarea>
                  </div>
                </b-col>
              </b-row> -->

              <hr class="mb-5 col-12" style="border-top-width: 2px" />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="mb-4" v-if="patientInfo.questions">
          <b-row align-v="center" class="mb-4">
            <b-col cols="12" class="mb-2">
              <h3 class="bold" style="letter-spacing: 0.5px">
                Health Questions
              </h3>
            </b-col>

            <b-col cols="12">
              <div class="health-question-wrapper">
                <div class="health-question">
                  <div
                    class="hq-list"
                    v-for="(healthQuestion, index) in patientInfo.questions"
                    :key="index"
                  >
                    <div class="tw-pb-6">
                      <label class="intake-label"
                        >{{ index + 1 }}. {{ healthQuestion.question }}
                      </label>
                      <p
                        v-if="healthQuestion.question != 'Stated allergies'"
                        class="pl-0 intake-input"
                        :style="`color: ${setColor(
                          healthQuestion.highlightcolor
                        )};border-bottom: 1px solid #D2DDEC; border-radius: 4px;`"
                      >
                        {{ healthQuestion.answer }}
                      </p>

                      <p
                        v-else
                        class="pl-0 intake-input"
                        :style="`color:${setColor(
                          healthQuestion.highlightcolor
                        )}`"
                      >
                        {{
                          healthQuestion.answer.replace(
                            /(\r\n|\n|\r)/gm,
                            "\r\n"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <b-row>
                <b-col
                  v-for="(healthQuestion, index) in patientInfo.questions"
                  :key="index"
                  cols="12"
                  md="6"
                >
                  <div role="group" class="mb-4">
                    <label class="intake-label"
                      >{{ index + 1 }}. {{ healthQuestion.question }}
                    </label>

                    <br />

                    <p
                      v-if="healthQuestion.question != 'Stated allergies'"
                      class="pl-0 intake-input"
                      :style="`color: ${setColor(
                        healthQuestion.highlightcolor
                      )} ; border-bottom: 1px solid #D2DDEC;
    border-radius: 4px;`"
                    >
                      {{ healthQuestion.answer }}
                    </p>
                    <b-form-textarea
                      v-else
                      disabled
                      max-rows="15"
                      class="pl-0 intake-input"
                      type="text"
                      style="overflow: hidden"
                      :value="
                        healthQuestion.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')
                      "
                      :style="`color:${setColor(
                        healthQuestion.highlightcolor
                      )}`"
                    ></b-form-textarea>
                  </div>
                </b-col>
              </b-row> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import TopBar from "@/components/partials/TopBar";
import Sidebar from "@/components/partials/SideNav";
import ModalBodyViewNotes from "@/components/patients/ModalBodyViewNotes.vue";

import { DashboardService } from "@/services/dashboard.service.js";
import { mapGetters } from "vuex";
import { format, fromUnixTime } from "date-fns";

export default {
  name: "Home",
  components: {
    TopBar,
    Sidebar,
    ModalBodyViewNotes,
  },
  data() {
    return {
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      currentUser: "",
      userImg: null,
      name: "",
      profileUrl: "",
      message: "",
      processing: false,
      selected: null,
      options: [
        { value: "a", text: "Name and DOB doesn’t match" },
        { value: "b", text: "Photo is blurry or dark" },
        { value: "c", text: "Government ID is expired" },
        { value: "d", text: "ID is cutoff" },
      ],

      isViewNotesOpen: false,
    };
  },
  methods: {
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    toggleModal(id) {
      this.$root.$emit("bv::hide::modal", id);
    },
    resetModal() {
      this.name = "";
      this.message = "";
      this.selected = null;
    },
    setColor(value) {
      if (this.$store.state.globals.isDarkMode === true) {
        if (value === "red") {
          return (value = "#FF8D40");
        }
      }
      return value;
    },
    getuser() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("user/getUser")
          .then((data) => {
            if (data.onboardingstatus === "PENDING") {
              if (data.onboardingstep === 1) {
                this.$router.push("/personal-details");
              } else if (
                data.onboardingstep === 2 ||
                data.onboardingstep === 2.1 ||
                data.onboardingstep === 2.2
              ) {
                this.$router.push("/upload-document");
              } else if (data.onboardingstep === 3) {
                this.$router.push("/summary");
              }
            }

            this.name = data.firstname + " " + data.lastname;
            this.profileUrl = data.profileUrl;
            resolve(data);
          })
          .catch((error) => {
            if (error.message) {
              this.$router.push("/personal-details");
            }
          });
      });
    },
    parseDate(date, stringFormat) {
      if (date) {
        return format(fromUnixTime(date), stringFormat);
      }
    },
  },
  computed: {
    hideSidebar() {
      return this.$route.name == "notification";
    },
    ...mapGetters({
      // user: "user/getUser",
      patientInfo: "patients/getPatientInfo",
    }),
  },
  async mounted() {
    this.patientId = localStorage.getItem("patientId");
    if (!localStorage.getItem("token")) {
      return this.$router.push("/login");
    }
    this.processing = true;
    await this.getuser()
      .then((user) => {
        this.$token = localStorage.getItem("accessToken");
        this.currentUser = `${user.firstname} ${user.lastname}`;

        if (user.onboardingstatus === "PENDING") {
          this.$router.push("/personal-details");
        }

        if (window.Intercom) {
          // window.intercomSettings.user_id = user.id;
          // window.intercomSettings.name = this.currentUser;
          // window.intercomSettings.email = user.email;

          window.Intercom("boot", {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
            name: this.currentUser,
            email: user.email,
            user_id: user.id,
          });
        }

        this.userImg = user.profileUrl;
      })
      .finally(() => (this.processing = false));
  },
};
</script>

<style scoped>
/deep/.modal-header.intake-header {
  border-bottom: 0;
  align-items: center;
}

/deep/.modal-body {
  padding-bottom: 0;
}

/deep/.modal-footer.issue {
  padding-top: 0;
  border-top: 0;
  justify-content: center;
}

/deep/.modal-footer.view-notes-modal {
  background: white;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  z-index: 99;
  width: 40%;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 0.15s;
}

/deep/.modal-footer.view-notes-modal.show-modal {
  transform: translateX(0);
}

@media (max-width: 768px) {
  /deep/.modal-footer.view-notes-modal {
    width: 90%;
  }
}
</style>

<style lang="scss" scoped>
.health-question-wrapper {
  column-count: 2;
  column-gap: 2em;
}

.health-question {
  display: grid;
  grid-template-rows: 1fr auto;
}

.hq-list > div {
  break-inside: avoid;
}

.intake-label {
  color: #333333;
  font-size: 12px;
}

.intake-input {
  border-color: #bdbdbd;
  color: #000;
}
// .content {
//   // background-color: #f5f8fc;
//   transition: all ease-in-out 0.5s;

//   &.is-hide {
//     background-color: $white;
//   }
// }

.main-content {
  min-height: calc(100vh - 83px);
  padding-top: 83px;
  transition: all ease-in-out 0.5s;

  @include media-breakpoint-up(xl) {
    padding-top: 62.4px;
    min-height: 100vh;

    &.is-hide {
      margin-left: 0 !important;
    }
  }
}

// @media (min-width: 768px) {
//   .main-content {
//     padding-top: 62.4px;
//     min-height: 100vh;

//     &.is-hide {
//       margin-left: 0 !important;
//     }
//   }
// }

// .main-content {
//   @include media-breakpoint-up(xxl) {
//     padding-top: (62.4px * 2);
//   }
// }

@media (max-width: 768px) {
  .cover-image {
    background: #fff !important;
  }
}

@media (max-width: 768px) {
  .c-logo {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
