import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import store from "../store";

export class NotificationService extends BaseService {
  static async getNotifications(nextPage) {
    try {
      const response = await this.request({ auth: true }).get(
        `notification?nextpage=${nextPage}`,
        {
          headers: {
            ...(store.state.globals.currentDoctor.id && {
              doctorid: store.state.globals.currentDoctor.id,
            }),
          },
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async markAsRead(id) {
    try {
      const response = await this.request({ auth: true }).put(
        `notification/${id}/read`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async markAllAsRead() {
    try {
      const response = await this.request({ auth: true }).put(
        `notification/all/read`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
